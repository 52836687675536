import React, {FC} from 'react';
import { Props } from 'react-select';
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const RubyApiSampleCode: FC<Props> = () => {
const codeSampleGetQuestion = `
    /**
    * Retrieves a ReCaptcha question of the specified type and word length.
    *
    * @param {string} type The type of ReCaptcha question to retrieve. 
    * type: Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX.
    * @param {integer} wordLength The length of the ReCaptcha word to retrieve.
    * @param {string} apiKey The API key obtained from https://www.thelastiqtest.com/re-captcha/apikey.
    * @return {Promise} A promise that resolves with the ReCaptcha question and answer.
    */
   
    require 'httparty'

    type = 'characters'
    word_length = 5
    apikey = 'fadshjgckxvl536789ewdgv'

    url = "https://www.thelastiqtest.com/v1/api/code-bender/re-captcha/#{type}/#{word_length}/#{apikey}"

    response = HTTParty.get(url)

    if response.success?
    puts "ReCaptcha question: #{response['question']}"
    else
    puts "Error: #{response.code} - #{response.message}"
    end
`;

const codeSampleAnswerQuestion =`
/**
 * Submits an answer to a ReCaptcha question.
 *
 * @param {string} question The ReCaptcha question.
 * @param {string} answer The answer to the ReCaptcha question.
 * @param {string} apiKey The API key obtained from https://www.thelastiqtest.com/re-captcha/apikey.
 * @return {Promise} A promise that resolves with a boolean indicating whether the answer was correct or not.


 This function takes in a question, answer and an api_key as input and makes a POST request to the specified endpoint
  with these parameters along with the api key. It then checks the status code of the response and raise error if not 200, 
  otherwise return the correct field.

 You should replace api_key with your actual API key, and check the API documentation to see if
  there are other headers or parameters that should be included in the request.

*/


def submit_answer(question, answer, apikey)
  uri = URI("https://www.thelastiqtest.com/v1/api/code-bender/answer-re-captcha")
  params = { question: question, answer: answer }

  http = Net::HTTP.new(uri.host, uri.port)
  http.use_ssl = true

  request = Net::HTTP::Post.new(uri.path)
  request.set_form_data(params)
  request["apikey"] = apikey

  response = http.request(request)

  if response.code == "200"
    JSON.parse(response.body)["correct"]
  else
    raise "Error submitting answer. HTTP code: #{response.code}"
  end
end

`;
    return(
       <>
            <h6>Get Question</h6>
            <SyntaxHighlighter language="javascript" style={monokaiSublime}>
                {codeSampleGetQuestion}
            </SyntaxHighlighter>
            <h6>Answer Question</h6>
            <SyntaxHighlighter language="javascript" style={monokaiSublime}>
                {codeSampleAnswerQuestion}
            </SyntaxHighlighter>
       </>
    )
}