import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../SubShare/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CIcon from '@coreui/icons-react';
import { cilEnvelopeLetter } from '@coreui/icons';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { verifyAccountEmail } from '../../features/company/verifyAccountEmailSlice';


const VerifyEmail: FC = () =>{
    
const { requestData, isLoading } = useAppSelector((state: { verifyAccountEmailAction: any; }) => state.verifyAccountEmailAction);
const dispatch = useAppDispatch();
let { token } = useParams();

useEffect(() => {
    const reqData = {
      token: token,
    }
    //@ts-ignore
    dispatch(verifyAccountEmail(reqData));
  }, [dispatch,token])

if (isLoading) {
    return <Spinner />
}
return (
    <Container>
      <Row>
        {/* @ts-ignore */}
        <div className='section' xs={6} md={12}>
          <h1>{requestData?.result.errorMessage}</h1>
          <p>Please <a href='/start-company'>sign in</a> and from there, request a new verification email.</p>
          <p>Contact <a href='/contact-us'>customer support</a> if this issue occurs repeatedly.
          <CIcon icon={cilEnvelopeLetter} className="me-9" />
          </p>
        </div>
      </Row>
    </Container>
  );
}

export default VerifyEmail;