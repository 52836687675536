import React, { FC, useState } from 'react';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CIcon from '@coreui/icons-react';
import { cilCreditCard } from '@coreui/icons';


const UpdateBtn = styled.span`
    background: transparent;
    border-color: transparent;
    color: #6d87dd;
    cursor: pointer;
    float: right;
    font-size: 12px; 
`;

interface Props{
  id: string; 
  instructions: string; 
}

const Instructions: FC<Props> = ({
  id,
  instructions,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <UpdateBtn onClick={handleShow}>
        <CIcon icon={cilCreditCard} className="me-2" />
          View instructions
    </UpdateBtn>
      <Modal show={show} onHide={handleClose}>
       
        <Modal.Body>
        <div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Instructions</label>
                  <textarea
                    id='instructions'
                    name='instructions'
                    value={instructions}
                    placeholder='Instructions (Optional)'
                    rows={7}
                    readOnly>
                  </textarea>
                </div>
               
              </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default  Instructions;