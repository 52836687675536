import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import codebenderService from './codebenderService';
import { getGameplayerToken } from '../helper/helper';


interface RequestData {
  question: string;
  answer: string,
}

interface AnswerCodeBenderState {
  requestData?: RequestData | null,
  isAnswerError: boolean,
  isAnswerSuccess: boolean,
  isAnswerLoading: boolean,
  answerMessage: any,
  answerScore: number;
  }


const initialState = {
  isAnswerError: false,
  isAnswerSuccess: false,
  isAnswerLoading: false,
  answerMessage: '',
  answerScore: 0,
} as AnswerCodeBenderState

export const answerCodeBender = createAsyncThunk(
  'codebender/answer',
  async (userAnswer: RequestData, thunkAPI) => {
    try {
      const token =  getGameplayerToken(thunkAPI);
      //@ts-ignore
      userAnswer = { ...userAnswer, user: thunkAPI.getState().authTry.user};
      return await codebenderService.answerCodeBender(userAnswer,token);
    } catch (error: any) {
      const answerMessage =
        (error.response &&
          error.response.data &&
          error.response.data.answerMessage) ||
        error.answerMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(answerMessage)
    }
  }
)

export const answerCodebenderSlice = createSlice({
  name: 'codebenderAnswer',
  initialState,
  reducers: {
    reset: (state) => {
      state.isAnswerLoading = false
      state.isAnswerSuccess = false
      state.isAnswerError = false
      state.answerMessage = ''
      state.answerScore = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(answerCodeBender.pending, (state) => {
        state.isAnswerLoading = true
      })
      .addCase(answerCodeBender.fulfilled, (state, action) => {
        state.isAnswerLoading = false
        state.isAnswerSuccess = true
        state.answerMessage = action.payload.message
        state.answerScore += action.payload.score
      })
      .addCase(answerCodeBender.rejected, (state, action) => {
        state.isAnswerLoading = false
        state.isAnswerError = true
        state.answerMessage = action.payload
      })
  },
})

export const { reset } = answerCodebenderSlice.actions;
export default answerCodebenderSlice.reducer;