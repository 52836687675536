import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import startCodeBenderService from './startCodeBenderService';

interface Company {
  email: string;
  company_name: string;
  password: string;
  errorMessage?: any;
  status?: any;
  result?:any;
  company?:any;
}

interface AuthSate{
  company: Company | null;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: any;
}

const initialState = {
  company: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as AuthSate


export const signup = createAsyncThunk('auth/signup', async (company: Company, thunkAPI) => {
    try {
      company = { ...company};
      return await startCodeBenderService.companySignUp(company)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })
export const logout = createAsyncThunk('auth/logout', async () => {
    await startCodeBenderService.logout()
})
export const signUpSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(signup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(signup.fulfilled, (state, action: PayloadAction<Company>) => {
      
        if(action.payload.company.status === 500){
            state.isLoading = false
            state.isError = true
            state.isSuccess = false
            state.message = action.payload.company.errorMessage;
            state.company = null
        }else{
            state.isLoading = false
            state.isSuccess = true
            state.company = action.payload
        }
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.company = null
      })
    .addCase(logout.fulfilled, (state) => {
        state.company = null
      })
  }

})

export const { reset } = signUpSlice.actions;
export default signUpSlice.reducer;