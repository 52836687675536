// @ts-nocheck
import React from 'react';

function Spinner() {
    return (
      <div className='loadingSpinnerContainer'>
        <div className='loadingSpinner'></div>
      </div>
    )
  }
  
  export default Spinner;