// @ts-nocheck
import React from 'react';

function Expired() {
    return (
    <div className='section' xs={6} md={12}>
        <h4>The test is <b className="text-danger">Expired</b></h4>
     </div>
    )
  }
  
  export default Expired;