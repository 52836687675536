import React, {FC} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import CIcon from '@coreui/icons-react';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { logout } from '../../features/auth/authSlice';
import {  
  cilList, 
  cilMenu,
  cilLockLocked,
  cilSettings,
  cilTask,cilFile} from '@coreui/icons';

interface Props{
    auth: any;
    userType: string;
}

const AppHeaderDropdown: FC<Props> = ({
    auth,
    userType
  }) => {

  const dispatch = useAppDispatch();
 
  const onClick = (e: { preventDefault: () => void; }) => {
    dispatch(logout());
  }
  return (
    <Dropdown>
    
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <CIcon icon={cilList} size="lg" />
        </Dropdown.Toggle><Dropdown.Menu>
        {(auth && userType === "company") ? 
           <>
            <Dropdown.Item href="/create-codebender">
            <CIcon icon={cilTask} className="me-2" />
                Create new IQtest
              </Dropdown.Item>
              <Dropdown.Item href="/manage-codebender">
                <CIcon icon={cilMenu} className="me-2" />
                Manage IQtest
              </Dropdown.Item>
              <Dropdown.Item href="/profile-settings">
                <CIcon icon={cilSettings} className="me-2" />
                  Edit profile 
              </Dropdown.Item>
           </>
          : '' }
          {/* <Dropdown.Item href="/profile-settings">
                <CIcon icon={cilSettings} className="me-2" />
                  Edit profile 
          </Dropdown.Item> */}
          <Dropdown.Item href="/re-captcha">
              <CIcon icon={cilFile} className="me-2" />
              Re-Captcha
          </Dropdown.Item>
          <Dropdown.Item href="/glabal-score">
              <CIcon icon={cilFile} className="me-2" />
              Global score
          </Dropdown.Item>
          <Dropdown.Item onClick={onClick}>
             <CIcon icon={cilLockLocked} className="me-2" />
             <span className='text-danger'>Log Out </span> 
          </Dropdown.Item>
        </Dropdown.Menu>
       
    </Dropdown>
  );
}

export default AppHeaderDropdown;