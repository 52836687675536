

const useCompanyLocalStorage = () => {
    //@ts-ignore
    const companyInfo = JSON.parse(localStorage.getItem('companyData'));
    if(!companyInfo) {
        window.location.href = "/";
    } 
    return companyInfo?.company;
};


export {
    useCompanyLocalStorage
};
