import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { reCaptchaPageUrls } from '../utils/constFunctions';


 const useRcNav = () => {
    const [isRcNav, setIsRcNav] = useState(false);
    const rcPages = reCaptchaPageUrls();
    const currentLocation = useLocation().pathname;
    const currentLocationName = currentLocation.replace(/[^a-zA-Z ]/g, "");
  
    useEffect(() => {
      if (rcPages.includes(currentLocationName)) {
        setIsRcNav(true);
      } else {
        setIsRcNav(false);
      }
    }, [rcPages,setIsRcNav,currentLocationName]);
  
    return isRcNav;
  };

  export default useRcNav;