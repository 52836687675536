import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../SubShare/Spinner';
import Expired from '../SubShare/Expired';
import {isExpired} from '../../utils/constFunctions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CIcon from '@coreui/icons-react';
import { cilClock } from '@coreui/icons';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { startTestBender } from '../../features/codebender/startTestSlice';
import  StartTestForm from '../SubShare/StartTestForm';


const StartTest: FC = () =>{
    
const { resultData, isResultLoading } = useAppSelector((state) => state.startTest);
const dispatch = useAppDispatch();
let { gameSetttingsId, gameSetttingsHandle } = useParams();

useEffect(() => {
    const dataReq = {
        id: gameSetttingsId,
        handle: gameSetttingsHandle
    }
    dispatch(startTestBender(dataReq));
  }, [dispatch,gameSetttingsId,gameSetttingsHandle])

if (isResultLoading || !resultData.payload) {
    return <Spinner />
}
if(isExpired(new Date(resultData.payload.data.expiry_date))){
    return <Expired />
}

return (
    <Container>
      <Row>
        {/* @ts-ignore */}
        <div className='section' xs={6} md={12}>
           <h3>{resultData.payload.data.company_name}</h3>

            <h4 style={{marginTop:'50px'}}>{resultData.payload.data.company_name} invites you to take thelastiqtest test</h4>
           <h4 className='text-info' style={{marginTop:'50px'}}>{resultData.payload.data.title}</h4>
           <p><b>Instruction:</b> {resultData.payload.data.instructions}</p>
           <p><b>Time: </b>  
                 <CIcon icon={cilClock} className="me-2" />{resultData.payload.data.test_time} minutes
           </p>
        </div>
        {/* @ts-ignore */}
        <div className='section' xs={6} md={12}>
            <p>Before you begin</p>
            <ul>
                <li>1. There's no option to pause. Make sure you will not be interrupted for TIME minutes.</li>
                <li>2. If you accidentally close your browser, use the invitation link to get back to your test.</li>
            </ul>
            <Row  className="col-md-6">
                <StartTestForm 
                  companyId={resultData.payload.data.company_id}
                  gameSetttingsId={gameSetttingsId}
                />
            </Row>
        </div>
      </Row>
    </Container>
  );
}

export default StartTest;