// @ts-nocheck
import React from 'react';
import { CBreadcrumb, CNavItem, CNavLink } from '@coreui/react';
import useSelectStyle from '../../hooks/rcNav';


const ReCaptchaTopNav = () => {
 
  
  return (
    <CBreadcrumb className="m-0 ms-2">
      <CNavItem>
        <CNavLink href="/re-captcha">
          <span id='apikeyNavBtn' className={useSelectStyle('recaptcha')}>ABOUT RE-CAPTCHA</span>
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="/api-reference">
          <span id='apikeyNavBtn' className={useSelectStyle('apireference')}>API REFERENCE</span>
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="/apikey">
          <span id='apikeyNavBtn' className={useSelectStyle('apikey')}>API KEY</span>
        </CNavLink>
      </CNavItem>
    </CBreadcrumb>
  )
}

export default ReCaptchaTopNav;
