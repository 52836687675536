import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import companyService from './companyService';


interface RequestData {
    email: string;
    token: string;
    result?: any;
    status?: any;
}

interface ResetPasswordState {
  requestData?: RequestData | null,
  isError: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  message: any,
  }

const initialState = {
  requestData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as ResetPasswordState

export const resetPassword = createAsyncThunk('codebender/resetPassword', async (requestData: RequestData, thunkAPI) => {
    try {
        
      return await companyService.resetPassword(requestData)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })

export const resetPasswordSlice = createSlice({
  name: 'resetPasswordAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        if(action.payload.result.status === 500){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.result.errorMessage
            state.requestData = action.payload
        }else{
            state.isLoading = false
            state.isSuccess = true
            state.requestData = action.payload
        }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = null
      })
  }

})

export const { reset } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;