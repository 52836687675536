import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter>
      <footer className="container">
        <div className="container">
          <div className="row">
          <div className="col-md-6">
              <span className="ms-1">&copy; 2023 thelastiqtest. All Rights Reserved</span>
            </div>
            <div className="col-md-6">
              <ul className="list-unstyled mb-0">
                <li><a href="/contact-us">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </CFooter>
  )
}

export default React.memo(AppFooter)
