import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../SubShare/Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { login, reset } from '../../features/auth/authSlice';

function CompanySignInForm() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
const { email, password } = formData;
const dispatch = useAppDispatch();
let navigate = useNavigate();

const { company, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.auth
  );

useEffect(() => {
  
    if (localStorage.getItem('isNavigatePage') && isSuccess && company) { 
        const page_to_visit = localStorage.getItem('isNavigatePage');
         navigate('/'+page_to_visit)
    }else{
      if (isSuccess && company) {
        window.location.reload();
        window.location.href = '/company-dashboard';
       }
       if (isSuccess || company) {
        window.location.href = '/company-dashboard';
      }
      
    }
    

   
    if (isError) {
      toast.error(message)
      dispatch(reset);
    }
    dispatch(reset())
  }, [company, isError, isSuccess, message, dispatch, navigate])


  const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }
  const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const userData = {
        email,
        password
      }

    dispatch(login(userData));
    setFormData({
        email : '',
        password : '',
    });
  }
  
if (isLoading) {
    return <Spinner />
  }
  return (
      <form className='company-signin-form' onSubmit={onSubmit}>
         <h4>Log in</h4>
          <div className='form-group'>
            <input
              type='email'
              className=''
              id='email'
              name='email'
              value={email}
              placeholder='Email'
              onChange={onChange}
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='password'
              className=''
              id='password'
              name='password'
              value={password}
              placeholder='Password'
              onChange={onChange}
              required
            />
          </div>
        <div className='form-group'>
          <button className='btn btn-block btn-success' type='submit'>
              Sign In
          </button>
          <label className='red-text'>{isError ? isError : ''}</label>
        </div>
        <div className=''>
          <span>Don’t have an account? <a className='link' href='/company-signup'> Create one here.</a></span><br></br>
          <span>Forgot password? <a className='link' href='/recover-password'> Recover password.</a></span>
        </div>
      </form>
  )
}

export default CompanySignInForm