import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import companyService from './companyService';
import { getCompanyToken } from '../helper/helper';

interface RequestData {
  companyId: string,
  id: string,
  handle: string
  result?:any;
}

interface ManageScoreState {
  resultData?: RequestData | null,
  isResultError: boolean,
  isResultSuccess: boolean,
  isResultLoading: boolean,
  resultMessage: any,
  }

const initialState = {
  isResultError: false,
  isResultSuccess: false,
  isResultLoading: false,
  resultMessage: '',
  resultData: null
} as ManageScoreState

export const getManageScore = createAsyncThunk(
  'codebender/globalscore',
  async (requestData: RequestData,thunkAPI) => {
    try {
      const token =  getCompanyToken(thunkAPI);
      return await companyService.getManageScore(requestData,token);
    } catch (error: any) {
      const resultMessage =
        (error.response &&
          error.response.data &&
          error.response.data.resultMessage) ||
        error.resultMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(resultMessage)
    }
  }
)

export const getManageScoreSlice = createSlice({
  name: 'manageScore',
  initialState,
  reducers: {
    reset: (state) => {
      state.isResultLoading = false
      state.isResultSuccess = false
      state.isResultError = false
      state.resultMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageScore.pending, (state) => {
        state.isResultLoading = true
      })
      .addCase(getManageScore.fulfilled, (state, action) => {
        state.isResultLoading = false
        state.isResultSuccess = true
        state.resultData = action.payload
        state.resultMessage = action.payload.message
      })
      .addCase(getManageScore.rejected, (state, action) => {
        state.isResultLoading = false
        state.isResultError = true
        state.resultMessage = action.payload
      })
  },
})

export const { reset } = getManageScoreSlice.actions;
export default getManageScoreSlice.reducer;