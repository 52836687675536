import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import companyService from './companyService';


interface RequestData {
    email: string;
    subject: string;
    fullname: string;
    message: any;
    result?: any;
    status?: any;
}

interface ContactUsState {
  requestData?: RequestData | null,
  isError: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  message: any,
  }

const initialState = {
  requestData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as ContactUsState

export const contactUs = createAsyncThunk('codebender/contactUs', async (requestData: RequestData, thunkAPI) => {
    try {
        
      return await companyService.contactUs(requestData)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })

export const contactUsSlice = createSlice({
  name: 'contactUsAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(contactUs.pending, (state) => {
        state.isLoading = true
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = action.payload
      })
      .addCase(contactUs.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = null
      })
  }

})

export const { reset } = contactUsSlice.actions;
export default contactUsSlice.reducer;