import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CNavLink,
  CNavItem,
  CHeaderDivider,
  CHeaderToggler
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';
import { ReCaptchaTopNav } from './index';
import useRcNav from '../../hooks/rcNav';

const DefaultHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1 d-md-none" onClick={toggleMenu}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
      
        {/* @ts-ignore */}
        <CHeaderBrand className="mx-auto d-md-none" to="/">
           <CNavLink to="/" component={NavLink}>
              <label className='thetitle'>TheLast<span>IQ</span>Test</label>
            </CNavLink>
        </CHeaderBrand>
        <CHeaderNav className={`d-md-flex me-auto ${isMenuOpen ? 'd-flex flex-wrap justify-content-between' : 'd-none'}`}>
          <CNavItem>
            {!isMenuOpen ? 
            <CNavLink to="/" component={NavLink}>
              <label className='thetitle'>TheLast<span>IQ</span>Test</label>
            </CNavLink>
            : '' }
          </CNavItem>
          <CNavItem>
            <CNavLink href="/glabal-score">Global score</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="/re-captcha">Re-Captcha</CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          {/* Additional items */}
        </CHeaderNav>
      </CContainer>
      {useRcNav() === true ? 
        <>
          <CHeaderDivider />
          <CContainer fluid>
            <ReCaptchaTopNav />
          </CContainer>
        </>
        : ''}
    </CHeader>
  )
}

export default DefaultHeader;
