import React, {FC} from 'react';
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import { Props } from 'react-select';
import RecaptchaNav from '../../../nav/RecaptchaNav';
import { PythonApiSampleCode } from  '../api-sample-code/PythonApiSampleCode';


const PythonApiSample: FC<Props> = () => {
   
  return (
    <CContainer>
      <CRow>
        <CCol lg="2" className="bg-default py-3">
          <RecaptchaNav />
        </CCol>
        <CCol lg="10" className="bg-default py-3 codeBenderContent">
          <div className='samplecode-div'>
            <h3>Python Sample Code</h3>
            <PythonApiSampleCode />
          </div>
        </CCol>
      </CRow>
  
    </CContainer>
  )
}
export default PythonApiSample;