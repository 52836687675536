import React, { FC, useState } from 'react';
import Spinner from '../../SubShare/Spinner';
import Container from 'react-bootstrap/Container';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { resetPassword } from '../../../features/company/resetPasswordSlice';
import { CCol, CRow } from '@coreui/react';
import zxcvbn from 'zxcvbn';
import CIcon from '@coreui/icons-react';
import { cilCheck } from '@coreui/icons';
import { useParams } from 'react-router-dom';
import RoverPasswordFeedback from './RoverPasswordFeedback';


const ResetPassword: FC = () =>{

const [formData, setFormData] = useState({
      password: '',
      });
    const { password } = formData;
const { requestData, isLoading } = useAppSelector((state: { resetPasswordAction: any; }) => state.resetPasswordAction);
const dispatch = useAppDispatch();
let { email, token } = useParams();


const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const userData = {
      email,
      token,
      password
      }
      //@ts-ignore
    dispatch(resetPassword(userData));
    setFormData({
      password : '',
    });
}
const checkPasswordCriteria = (isType: string,password: string) =>{

  if (password.length < 1) {
      return false;
  }else if (isType === "lenght" && password.length > 7) {
    return true;
  }else if (isType === "uppercase" && (/[A-Z]/.test(password))) {
    return true;  
  }else if (isType === "lowercase" && (/[a-z]/.test(password))) {
    return true; 
  }else if (isType === "special_character" && (/[\W_]/.test(password))) {
    return true;
  }else {
    return false;
  }
}

const getPasswordStrength = (password: string): string => {
  const passwordScore = zxcvbn(password).score;
  if(!password || password === null){
    return '';
  }
  if (passwordScore === 0) {
    return 'Weak';
  } else if (passwordScore === 1 || passwordScore === 2) {
    return 'Medium';
  } else {
    return 'Strong';
  }
};

const isPasswordValid =
password.length >= 8 &&
/[A-Z]/.test(password) &&
/[a-z]/.test(password) && (/[\W_]/.test(password));

const passwordStrength = getPasswordStrength(password);
const classColor = (score:string) =>{

  if(score === 'Weak'){
    return 'red-text';
  }else if(score === 'Medium'){
    return 'yellow-text';
  }else if(score === 'Strong'){
    return 'green-text';
  }else{
    return '';
  }
}

if (isLoading) {
    return <Spinner />
}

return (
    <Container>
      <CRow>
        <CCol lg="6" className="container h-100 d-flex justify-content-center py-3">
        {
          requestData?.result ?

        <RoverPasswordFeedback 
          status={requestData.result.status}
          message={requestData.result.message}
          errorMessage={requestData.result.errorMessage} />
          : 
        
        // @ts-ignore 
        <div className='section' xs={12} md={6}>
            <form className='company-signin-form' onSubmit={onSubmit}>
            <h2> Password Reset</h2>
            <p>Enter your new password for your account.</p>
            <div className='form-group'>
                <input
                type='password'
                className=''
                id='password'
                name='password'
                value={password}
                placeholder='password'
                onChange={onChange}
                required
                />
            </div>
            <div className='form-group'>
             <>
              <li>Password must be 8 characters or more 
                <i className='green-text'>
                  {checkPasswordCriteria('lenght',password) ? <CIcon icon={cilCheck} className="me-9" /> : ''}
                </i>
              </li>
              <li>Password must have at least one Upper case
                <i className='green-text'>
                  {checkPasswordCriteria('uppercase',password) ? <CIcon icon={cilCheck} className="me-9" /> : ''}
                </i>
              </li>
              <li>Password must have at least one Lower case
                <i className='green-text'>
                  {checkPasswordCriteria('lowercase',password) ? <CIcon icon={cilCheck} className="me-9" /> : ''}
                </i>
              </li>
              <li>Password must have at least one Special characters
                <i className='green-text'>
                  {checkPasswordCriteria('special_character',password) ? <CIcon icon={cilCheck} className="me-9" /> : ''}
                </i>
              </li>
             </>
             <label>
                  Password strength: <strong className={classColor(passwordStrength)}>
                    {passwordStrength}
                  </strong>
            </label>
            <p>Passwords will be checked against datasets like.</p>
             {isPasswordValid ? (
                  <button className="btn btn-block btn-success" type="submit" style={{color:'#FFF'}}>
                    Reset My Password
                  </button>
                ) : (
                  <button
                    className="btn btn-block btn-success"
                    type="submit"
                    disabled
                  >
                    Reset My Password
                  </button>
              )}
             <label className='red-text'>{requestData?.result.status === 401 ? requestData?.result.errorMessage : ''}</label>
             <label className='green-text'>{requestData?.result.status !== 401 ? requestData?.result.message : ''}</label>
            </div>
            </form>
        </div>
      }
        
        </CCol>
      </CRow>
    </Container>
  );
}

export default ResetPassword;