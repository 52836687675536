import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    CForm
  } from '@coreui/react';
import { createRecaptchaApiKey, reset } from '../../../features/recaptcha/createRecaptchaApiKeySlice';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';


const UpdateBtn = styled.span`
    cursor: pointer;
    padding: 5px;
    font-size: 11px;
    float: right;
    margin-top: -10px;
    font-weight: 400;
    font-family: var(--sail-font-family);
    text-transform: inherit;
    vertical-align: bottom;
    border: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
    font-weight: 500;
    padding: 0.625rem 1.5rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

interface Props{
  companyId: string;
}

const CreateAPIKey: FC<Props> = ({
  companyId,
}) => {
const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
        (state: { createRecaptchaApiKeyAction: any; }) => state.createRecaptchaApiKeyAction
)
const [formData, setFormData] = useState({
    domain: ''
  });
const { domain } = formData;

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const dispatch = useAppDispatch();
const navigate = useNavigate();

useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset());
    }
    if (isSuccess || requestData) {
      toast.success('Api key generated..')
      window.location.reload();
    }
  }, [requestData, isError, isSuccess, message, navigate, dispatch])

 

 useEffect(() => {
  // @ts-ignore
    if (!JSON.parse(localStorage.getItem('companyData'))) {
        const nav_to_this_page = 'apikey'
        localStorage.setItem("isNavigatePage", nav_to_this_page);
        navigate('/start-company')
    }
}, [navigate])

const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
}
const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

const createApikeyData = {
    user_handle: companyId,
    domain_name: domain
}

dispatch(createRecaptchaApiKey(createApikeyData)); 
}
return (
    <>
    <UpdateBtn onClick={handleShow}>
        +
        Create new Api Key
    </UpdateBtn>
      <Modal show={show} onHide={handleClose}>
      <CForm onSubmit={onSubmit}>
        <Modal.Body>
        <div>
            <p className='create-api-key-warning-msg'>
                After creating an API key, you can begin utilizing our reCAPTCHA API. 
                It is important to ensure the safety and protection of the key.
                Do you confirm that you wish to continue?
            </p>
            <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Domain name (eg. example.com)</label>
                  <input
                    id='domain'
                    name='domain'
                    value={domain}
                    placeholder='domain'
                    onChange={onChange}
                    required>
                  </input>
                </div>
            
        </div>
        </Modal.Body>
        <Modal.Footer style={{border:'3px #FFF solid'}}>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading ? <Spinner animation="border" variant="info" />: 
          <Button variant="success"  type='submit'>
            Yes continue
          </Button>
         }
        </Modal.Footer>
        </CForm>
      </Modal>
    </>
  );
}

export default  CreateAPIKey;