import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { toast } from 'react-toastify';
import { getCodeBenderSettings } from '../../features/company/codeBenderSettingsSlice';
import {
  CCol,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {cilCopy} from '@coreui/icons';
import {Table, Spinner } from 'react-bootstrap';
import ShareList from '../SubShare/social/index';
import dateFormat from "dateformat";
import DeleteCodeBenderSettings from './actions/DeleteCodeBenderSettings';
import UpdateCodeBender from './actions/UpdateCodeBender';
import Instructions from '../SubShare/modal/Instructions';
import {isExpired} from '../../utils/constFunctions';
import { useCompanyLocalStorage } from '../../hooks/companyAuth';


const ManageCodeBender: FC = () => {

const { company } = useAppSelector((state) => state.auth);
const { resultData, isResultLoading, isResultError, resultMessage } = useAppSelector((state) => state.getCodeBenderSettings);

const dispatch = useAppDispatch();

useEffect(() => {
    if (isResultError) {
      // toast.error(resultMessage)
    }
  }, [isResultError, resultMessage])

const checkCompanyLStorage = useCompanyLocalStorage();
// @ts-ignore
const companyId = company?.company.user.id ? company?.company.user.id : checkCompanyLStorage.user.id;
useEffect(() => {
  // @ts-ignore
  dispatch(getCodeBenderSettings(companyId));
  // @ts-ignore
}, [dispatch,companyId] );
return (
    <CRow className="justify-content-center">
      <CCol xs={10} className="row">
        <h4>Manage IQTest</h4>
            {isResultLoading ? <Spinner animation="border" variant="info" /> : ''}
              { !resultData ? 
                  <span className="text-danger" style={{marginTop:'270px'}}>You don't have any record yet. <a href="/create-codebender" className='btn btn default'>Click here</a> to create a new record</span>
                  :
                   <Table className='table-container' striped bordered hover size="sm" responsive="lg" style={{border:'1px #CCC solid'}}>
                    <thead>
                        <tr>
                          <th>Title</th>
                          <th>Instructions</th>
                          <th>Type</th>
                          <th>Test Time</th>
                          <th>Word Length</th>
                          <th>Number Of Question</th>
                          <th>Users Attempted</th>
                          <th>Share</th>
                          <th>Expiry date</th>
                          <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                       {/*  @ts-ignore */}
                     {resultData.result && resultData.result.map((key: any, index: any) =>
                        <tr key={index}>
                        <td>{key.title}</td>
                        <td>
                        <Instructions 
                          id={key.id} 
                          instructions={key.instructions}
                        />
                        </td>
                        <td><span style={{textTransform:'capitalize'}}>{key.question_type}</span></td>
                        <td>{key.test_time} Minutes</td>
                        <td><span className='questionBox'>{key.wordLength}</span></td>
                        <td>{key.numberOfQuestion}</td>
                        <td>
                            {
                                key.users_attempted > 0 ? 
                                <>
                                <span className='questionBox-two'>{key.users_attempted} users</span>
                                <br />
                                <a href={`/manage-score/${key.id}/${key.handle}/${key.title}`}  className='btn-two btn-primary-two'>View Result</a>
                                </>
                                :  <span className='questionBox-two'>{key.users_attempted} attempt..</span>
                            }
                        </td> 
                        <td height='130'>
                           {
                            isExpired(new Date(key.expiry_date)) ? 
                            <code className="PropTable-Code-module--cls2--1p8A9 PropTable-Code-module--cls1--2iQb5">test expired..</code> : 
                            <>
                             <label className='copy-test-link' 
                               onMouseDown={() => toast.success('Link copied..')}
                               onClick={() => navigator.clipboard.writeText(`https://thelastiqtest.com/start-bending-code/${key.id}/${key.handle}`)}>
                                <CIcon icon={cilCopy} className="me-2" /><label style={{fontSize:'10px', cursor:'pointer'}}>Copy test link</label>
                            </label>
                              <ShareList CodeBenderUrl={`https://thelastiqtest.com/start-bending-code/${key.id}/${key.handle}`} />
                            </>
                           }
                        </td>
                        <td>{dateFormat(key.expiry_date, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</td>
                        <td> 
                          <UpdateCodeBender 
                            id={key.id} 
                            companyId={key.company_id}
                            cTitle={key.title}
                            cInstructions={key.instructions}
                            cQuestion_type={key.question_type}
                            cWordLength={key.wordLength}
                            cNumberOfQuestion={key.numberOfQuestion}
                            cTest_time={key.test_time}
                            cExpiry_date={key.expiry_date}
                          />
                          <DeleteCodeBenderSettings 
                             id={key.id} 
                             cTitle={key.title}
                             companyId={key.company_id}
                            />
                          </td>
                        </tr>
                    )}
                        
                    </tbody>
                </Table>
               }
      </CCol>
    </CRow>
  )
}

export default ManageCodeBender;
