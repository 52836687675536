// @ts-nocheck
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CompanySignInForm from '../components/company/CompanySignInForm';

function StartCompany() {
 
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col className="col-md-4"> 
          <CompanySignInForm />
        </Col>
      </Row>
    </Container>
  )
}

export default StartCompany;