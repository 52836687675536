import axios from 'axios'
import { config } from '../../common/config';

const API_URL = config.baseUrl.default;
 
const createRCApikey = async (requestData: object,token: string) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    const response = await axios.post(API_URL + 'create/re-captcha/api-key', requestData, config)
    return response.data;
} 
const deactivateRCApikey = async (requestData: object,token: string) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  const response = await axios.post(API_URL + 'deactivate/re-captcha/api-key', requestData, config)
  return response.data;
} 

const getRCApikey = async (companyId: string, token:string) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  
  const response = await axios.get(API_URL + `get-re-captcha-apikey/${companyId}`,config);
  return response.data;
}

const recaptchaService = {
  createRCApikey,
  deactivateRCApikey,
  getRCApikey
}

export default recaptchaService;