import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import companyService from './companyService'
import { getCompanyToken } from '../helper/helper';


interface Company {
  companyId?: string;
  errorMessage?: any;
  status?: any;
  result?:any;
  company?:any;
}

interface CodeBenderSettingsState {
  isResultError: boolean;
  isResultSuccess: boolean;
  isResultLoading: boolean;
  resultMessage: any;
  resultData: Company | null;
}

const initialState = {
  isResultError: false,
  isResultSuccess: false,
  isResultLoading: false,
  resultMessage: '',
  resultData: []
} as CodeBenderSettingsState


export const getCodeBenderSettings = createAsyncThunk(
  'codebender/getcodebender',
  async (companyId: string,thunkAPI) => {
    try {
      const token =  getCompanyToken(thunkAPI);
      return await companyService.getCodeBenderSettings(companyId,token);
    } catch (error: any) {
      const resultMessage =
        (error.response &&
          error.response.data &&
          error.response.data.resultMessage) ||
        error.resultMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(resultMessage)
    }
  }
)

export const getCodeBenderSettingsSlice = createSlice({
  name: 'getCodeBenderSettings',
  initialState,
  reducers: {
    reset: (state) => {
      state.isResultLoading = false
      state.isResultSuccess = false
      state.isResultError = false
      state.resultMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCodeBenderSettings.pending, (state) => {
        state.isResultLoading = true
      })
      .addCase(getCodeBenderSettings.fulfilled, (state, action) => {
        if(action.payload.result.status === 500){
            state.isResultLoading = false
            state.isResultError = true
            state.resultMessage = action.payload.result.errorMessage
            state.isResultLoading = false
            state.resultData = null
        }else{
            state.isResultLoading = false
            state.isResultSuccess = true
            state.isResultLoading = false
            state.resultData = action.payload
        }
      })
      .addCase(getCodeBenderSettings.rejected, (state, action) => {
        state.isResultLoading = false
        state.isResultError = true
        state.resultMessage = action.payload
      })
  },
})

export const { reset } = getCodeBenderSettingsSlice.actions;
export default getCodeBenderSettingsSlice.reducer;