import { createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import recaptchaService from './recaptchaService';
import { getCompanyToken } from '../helper/helper';


interface Company {
  companyId?: string;
  errorMessage?: any;
  status?: any;
  result?:any;
  company?:any;
}

interface RecaptchaState {
  isResultError: boolean;
  isResultSuccess: boolean;
  isResultLoading: boolean;
  resultMessage: any;
  resultData: Company | null;
}
const initialState = {
  isResultError: false,
  isResultSuccess: false,
  isResultLoading: false,
  resultMessage: '',
  resultData: []
} as RecaptchaState


export const getRecaptchaApiKeys = createAsyncThunk(
  'codebender/getRecaptchaApiKeys',
  async (companyId: string,thunkAPI) => {
    try {

      const token =  getCompanyToken(thunkAPI);
           
      return await recaptchaService.getRCApikey(companyId,token);
    } catch (error: any) {
      const resultMessage =
        (error.response &&
          error.response.data &&
          error.response.data.resultMessage) ||
        error.resultMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(resultMessage)
    }
  }
)

export const getRecaptchaApiKeysSlice = createSlice({
  name: 'getRecaptchaApiKeysAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isResultLoading = false
      state.isResultSuccess = false
      state.isResultError = false
      state.resultMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecaptchaApiKeys.pending, (state) => {
        state.isResultLoading = true
      })
      .addCase(getRecaptchaApiKeys.fulfilled,(state, action: PayloadAction<Company>) => {
        if(action.payload.result.status === 500){
            state.isResultLoading = false
            state.isResultError = true
            state.resultMessage = action.payload;//action.payload.company.errorMessage
            state.isResultLoading = false
            state.resultData = action.payload
        }else{
            state.isResultLoading = false
            state.isResultSuccess = true
            state.isResultLoading = false
            state.resultData = action.payload
        }
      })
      .addCase(getRecaptchaApiKeys.rejected, (state, action) => {
        state.isResultLoading = false
        state.isResultError = true
        state.resultMessage = action.payload
      })
  },
})

export const { reset } = getRecaptchaApiKeysSlice.actions;
export default getRecaptchaApiKeysSlice.reducer;