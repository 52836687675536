import React, {FC} from 'react';
import Table from 'react-bootstrap/Table';
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import { Props } from 'react-select';
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { APIRefrenceSample } from './api-ref-sample/APIRefrenceSample';
import RecaptchaNav from '../nav/RecaptchaNav';



export const APIRefrence: FC<Props> = () => {
    const codeGetRCQString = `/v1/api/code-bender/re-captcha/{question_type}/{wordLength}/{apikey}`;
    const codePostRCQString = `v1/api/code-bender/answer-re-captcha`;

    const AnswerRecaptchaQuestion = `Answer re-captcha question base on question and apikey provided.

    /**
    * Submits an answer to a ReCaptcha question.
    *
    * @param string question The ReCaptcha question.
    * @param string answer The answer to the ReCaptcha question.
    * @param string apiKey The API key obtained from https://www.thelastiqtest.com/re-captcha/apikey.
    * @return Promise A promise that resolves with a boolean indicating whether the answer was correct or not.
    */`;

  return (
    <CContainer>
      <CRow>
        <CCol lg="2" className="bg-default py-3">
          <RecaptchaNav />
        </CCol>
          <CCol lg="10" className="bg-default py-3 codeBenderContent">
           
           <div className='api-ref-content'>
                <h4>Thelastiqtest reCAPTCHA API</h4>
                <p>Prevent fraudulent activity, spam, and abuse on your website while also minimizing inconvenience to your users.</p>
                <h6>Service: </h6>
                <p>To call this service, we recommend that you use the thelastiqtest npm libraries.
                If your application needs to use your own libraries to call this service, use the following
                 information when you make the API requests.</p>
                 <h6>Service endpoint</h6>
                 <p>An API service's network address is specified by a base URL called a service endpoint. A single service may have 
                    multiple service endpoints. In our case, the following service endpoint is being used, and 
                    all URIs about it are relative to this endpoint:</p>
                <h6>REST Resource: <span className='CodeBenderPrimaryColor'>api/code-bender</span></h6>

                <Table striped="columns" className='rc-api-table-content'>
                    <thead>
                        <tr style={{border:'3px #FFF solid'}}>
                            <th style={{width:'143px'}}>Method</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='rc-api-table-content'>
                        <tr>
                            <td><label>get RC question</label></td>
                            <td>
                            <SyntaxHighlighter style={monokaiSublime}>
                                {codeGetRCQString}
                            </SyntaxHighlighter>
                                Returns a random question base on question type provided.
                               <p style={{fontSize:'14px',marginBottom:'-1px',fontWeight:'bold'}}>Parameters:</p>
                                <ul>
                                    <li>*@param <strong>question_type</strong> (string, required): The type of ReCaptcha question to retrieve. Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX.</li>
                                    <li>*@param <strong>wordLength</strong> (integer, required): The length of the ReCaptcha word to retrieve.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><label>answer RC question</label></td>
                            <td>
                              <div>
                              <SyntaxHighlighter style={monokaiSublime}>
                                {codePostRCQString}
                              </SyntaxHighlighter>
                              </div>
                              <SyntaxHighlighter style={monokaiSublime}>
                                {AnswerRecaptchaQuestion}
                              </SyntaxHighlighter>
                            </td>
                        </tr>
                    </tbody>
                </Table>
           </div>
           <div className='api-ref-content'>
                <h6 style={{marginBottom:'40PX'}}>API Reference Sameple</h6>

                <APIRefrenceSample />
           </div>
        </CCol>
      </CRow>
  
    </CContainer>
  )
}