// @ts-nocheck
import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import {  FaShare,
          FaFacebook, 
          FaTwitter, 
          FaLinkedin,
          FaEnvelope, FaCommentAlt } from 'react-icons/fa';

export const socials = [
  {
    outlet: "LinkedIn",
    href:
      `https://www.linkedin.com/shareArticle?url=`,
    background: "#0a66c2",
    color: "white",
    label: "Share on LinkedIn",
    icon: <FaLinkedin size={20} />
  },
  {
    outlet: "Facebook",
    href:
      `https://www.facebook.com/sharer.php?u=`,
    background: "#3b5898",
    color: "white",
    label: "Share on Facebook",
    icon: <FaFacebook size={20} />
  },
  {
    outlet: "Twitter",
    href:
      `https://twitter.com/intent/tweet?url=`,
    background: "#00aced",
    color: "white",
    label: "Share on Twitter",
    icon: <FaTwitter size={20} />
  },
  {
    outlet: "Email",
    href:
      `mailto:?subject=The'%20Last%20Code%20Bender%20Test&body=`,
    background: "#dd4b39",
    color: "white",
    label: "Share via Email",
    icon: <FaEnvelope size={20} />
  },
  {
    outlet: "SMS",
    href:
      `sms:?body=`,
    background: "#7bcb20",
    color: "white",
    label: "Share via SMS",
    icon: <FaCommentAlt size={20} />
  }
];

export default function ShareList(props) {
  const [menuActive, setMenuActive] = useState(false);
  const handleToggleMenu = () => {
    setMenuActive((menuActive) => !menuActive);
  };
  const [codeBenderUrl] = useState(props.CodeBenderUrl);

  const socialLinks = socials.map((social, index) => {
    return (
      <SocialLink
        as="a"
        href={social.href+codeBenderUrl}
        target="_blank"
        rel="noreferrer"
        aria-label={social.label}
        role="button"
        isActive={menuActive}
        background={social.background}
        color={social.color}
        position={index}
        key={index}
      >
        {social.icon}
      </SocialLink>
    );
  });

  return (
    <Container>
      <ShareContainer>
        <ShareButton
          isActive={menuActive}
          aria-label="Share "
          aria-expanded={menuActive}
          role="button"
          background="#fcab91"
          color="white"
          onClick={handleToggleMenu}
        >
         <FaShare size={20} />
        </ShareButton>
        {socialLinks}
      </ShareContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ShareContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ShareButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 50%;
  outline: none;
  border: 2px solid ${({ background }) => background};
  padding: 8px;
  transform: ${({ isActive }) => (isActive ? "scale(0.8)" : "scale(1.0)")};
  transition: all 0.2s, transform 0.2s 0.2s;
  :hover {
    background: white;
    color: ${({ background }) => background};
  }
`;

const SocialLink = styled(ShareButton)`
  position: absolute;
  z-index: 0;
  transform: none;
  transition: top 0.2s ${({ position }) => `${position * 50}ms`},
    left 0.2s ${({ position }) => `${position * 50}ms`};
  left: ${({ isActive, position }) =>
    isActive ? `${(-1) ** position * Math.ceil(position / 2) * 50}px` : "0"};
  top: ${({ isActive }) => (isActive ? `50px` : "0")};
  box-shadow: ${({ isActive }) => (isActive ? `0 4px 10px 0 gray` : `0`)};
`;

