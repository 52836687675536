import React from 'react';
import Button from 'react-bootstrap/Button';
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import { Link } from 'react-router-dom';
import {checkCompanyAndUserAuth} from '../utils/constFunctions';


function Home() {
  const checkAuth  = checkCompanyAndUserAuth();
 
  return (
    <CContainer>
      <CRow>
        <CCol lg="6" className="bg-default py-3">
          <h1 className='thetitle-two'>
                One of the most accurate online 
                <span className="CodeBenderPrimaryColor"> <br></br>IQ Test</span>
            </h1>
            <br></br><br></br>
             <p>Test your cognitive skills. <span className="CodeBenderPrimaryColor">Start for free.</span></p>
           
              {
                checkAuth?.company || checkAuth?.user ? '' :
                <div style={{ marginTop: "40px", marginBottom: "40px" }}>
                  <Link to='/start-bending-code/9/3fd4e22a-f8f3-4480-a0c1-044bf363539e'>
                    <Button variant="primary" size="lg" active>
                      As Candidate
                    </Button>
                  </Link>
                  <Link to='/start-company' style={{ marginLeft: "20px" }}>
                    <Button variant="secondary" size="lg" active>
                      As Company
                    </Button>
                  </Link>
                </div>
                }
               
                 
         </CCol>
          <CCol lg="6" className="bg-default py-3 codeBenderContent">
          <label className="textCodebenderThreeD">A1-B2-C3-D4</label>
                  <h4 className='test-title'>Test employee IQ during interview using <span className="CodeBenderPrimaryColor"> Thelastiqtest</span>, anywhere</h4>
        </CCol>
      </CRow>
      <CRow className='moreInfo'>
                  <CCol lg="12" className="bg-default py-3">
                  <h1 className='thetitle-two'>Your success is our <span className='CodeBenderPrimaryColor'>highest priority</span></h1> 
                  <p>
                    Thelastiqtest is a tool that enhances your ability to remember and retain information. 
                    Our API can be utilized to distinguish between humans and bots on websites in order to prevent spam and abuse. 
                    While it is simple for humans to complete, it is difficult for bots and other malicious software to solve. 
                    By incorporating thelastiqtest's reCAPTCHA into a website, you can prevent automated programs from accessing 
                    the site while allowing legitimate users to easily enter.
                  </p>
                  </CCol>
                </CRow>
    </CContainer>
  )
}

export default Home;