import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CIcon from '@coreui/icons-react';
import { cilTask } from '@coreui/icons';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { updateCodeBenderSetting } from '../../../features/company/updateCodeBenderSettingSlice';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';


const UpdateBtn = styled.span`
    background: transparent;
    border-color: transparent;
    color: #39c0ed;
    cursor: pointer;
    float: right;
    font-size: 12px; 
    margin-top: 10px;
`;

interface Props{
  id: string; 
  companyId: any;
  cTitle: string;
  cInstructions: string;
  cQuestion_type: any;
  cWordLength: any;
  cNumberOfQuestion: any; 
  cTest_time: any;
  cExpiry_date: any;
}

const UpdateCodeBender: FC<Props> = ({
  id, companyId, cTitle, cInstructions, cQuestion_type,
  cWordLength, cNumberOfQuestion, cTest_time, cExpiry_date
  
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.updateCodeBenderSettingAction
  )

const [formData, setFormData] = useState({
    title: cTitle,
    instructions: cInstructions,
    question_type: cQuestion_type,
    wordLength: cWordLength,
    numberOfQuestion: cNumberOfQuestion,
    test_time: cTest_time,
    expiry_date:  moment(cExpiry_date).format("YYYY-MM-DD")
  });
const { title, instructions, question_type, wordLength, numberOfQuestion, test_time, expiry_date } = formData;

const dispatch = useAppDispatch();
const navigate = useNavigate();
  useEffect(() => {
 
    if (isSuccess || requestData) {
        setShow(false);
        window.location.reload();
        toast.success('Record updated..')
    }
  }, [requestData, isError, isSuccess, message, navigate, dispatch])

const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
}
const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const updateData = {
        company_id: companyId,
        id: id,
        title, 
        instructions, 
        question_type, 
        wordLength, 
        numberOfQuestion, 
        test_time,
        expiry_date
    }
    dispatch(updateCodeBenderSetting(updateData));
}
  return (
    <>
    <UpdateBtn onClick={handleShow}>
        <CIcon icon={cilTask} className="me-2" />
        update
    </UpdateBtn>
      <Modal show={show} onHide={handleClose}>
       
        <Modal.Body>
            <form onSubmit={onSubmit}>
            <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Title (eg. IQ test)</label>
                  <input
                    id='title'
                    name='title'
                    value={title}
                    placeholder='Title'
                    onChange={onChange}
                    required>
                  </input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Instructions (Optional)</label>
                  <textarea
                    id='instructions'
                    name='instructions'
                    value={instructions}
                    placeholder='Instructions (Optional)'
                    onChange={onChange}
                    rows={3}
                    required>
                  </textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Question Type</label>
                   <select 
                        id='question_type'
                        name='question_type'
                        value={question_type}
                        onChange={onChange}
                        required>
                        <option value="">Select Type</option>
                        <option value="random">Characters and Numbers Question</option>
                        <option value="characters">Characters Question</option>
                        <option value="numbers">Number Question</option>
                   </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Word Length</label>
                   <select 
                        id='wordLength'
                        name='wordLength'
                        value={wordLength}
                        onChange={onChange}
                        required>
                            <option value="">Select Word Length</option>
                            {(() => {
                                const arr = [];
                                for (let i = 3; i < 21; i++) {
                                    arr.push(
                                        <option value={i} key={i}>{i}</option>
                                    );
                                }
                                return arr;
                            })()}
                   </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Number Of Questions</label>
                   <select 
                        id='numberOfQuestion'
                        name='numberOfQuestion'
                        value={numberOfQuestion}
                        onChange={onChange}
                        required>
                        <option value="">Select Number Of Questions</option>
                            {(() => {
                                const arr = [];
                                for (let i = 10; i < 110; ) {
                                    arr.push(
                                        <option value={i} key={i}>{i}</option>
                                    );
                                    i += 10;
                                }
                                return arr;
                            })()}
                   </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">
                    Test Time (How many minutes will this test take?)
                  </label>
                   <select 
                        id='test_time'
                        name='test_time'
                        value={test_time}
                        onChange={onChange}
                        required>
                        <option value="">Select Time</option>
                            {(() => {
                                const arr = [];
                                for (let i = 10; i < 181; ) {
                                    arr.push(
                                        <option value={i} key={i}>{i} Minutes</option>
                                    );
                                    i += 10;
                                }
                                return arr;
                            })()}
                   </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Expiry Date</label>
                  <input
                        type='date'
                        className=''
                        id='expiry_date'
                        name='expiry_date'
                        value={expiry_date}
                        placeholder='Expiry Date'
                        onChange={onChange}
                        required
                    />
                </div>
                <div className='form-group'>
                    <button className='btn btn-block btn-success' type='submit'>
                        Update
                    </button>
                    <label>{isLoading}</label>
                </div>
            </form>
            <span className='color-primary'>
                {isError ? 
                 <><br /><br /><code className="PropTable-Code-module--cls2--1p8A9 PropTable-Code-module--cls1--2iQb5">{message}</code></>
                 : ''}
                  {isLoading ? <Spinner animation="border" variant="info" />: ''}
            </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateCodeBender;