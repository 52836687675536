import React, {FC} from 'react';
import { Props } from 'react-select';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { JavascriptApiSampleCode } from  './api-sample-code/JavascriptApiSampleCode';
import { JavaApiSampleCode } from  './api-sample-code/JavaApiSampleCode';
import { CSharpApiSampleCode } from './api-sample-code/CSharpApiSampleCode';
import { PHPApiSampleCode } from './api-sample-code/PHPApiSampleCode';
import { PythonApiSampleCode } from './api-sample-code/PythonApiSampleCode';
import { RubyApiSampleCode } from './api-sample-code/RubyApiSampleCode';




export const APIRefrenceSample: FC<Props> = () => {

    return(
        <Tabs  defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="javascript" title="Javascript">
                <JavascriptApiSampleCode />
            </Tab>
            <Tab eventKey="java" title="Java">
                <JavaApiSampleCode />
            </Tab>
            <Tab eventKey="c#" title="C#">
                <CSharpApiSampleCode />
            </Tab>
            <Tab eventKey="php" title="PHP">
                <PHPApiSampleCode />
            </Tab>
            <Tab eventKey="python" title="Python">
                <PythonApiSampleCode />
            </Tab>
            <Tab eventKey="ruby" title="Ruby">
                <RubyApiSampleCode />
            </Tab>
      </Tabs>
    )
}