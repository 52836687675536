import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    CForm
  } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {cilTrash} from '@coreui/icons';
import { deactivateRecaptchaApiKey, reset } from '../../../features/recaptcha/deactivateRecaptchaApiKeySlice';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';


const UpdateBtn = styled.span`
    cursor: pointer;
`;

interface Props{
  id: string; 
  domainName: string; 
  apikey: string;
  companyId: string;
}

const DeactivateAPIKey: FC<Props> = ({
  id,
  domainName,
  apikey,
  companyId,
}) => {
const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
        (state) => state.deactivateRecaptchaApiKeyAction
)

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const dispatch = useAppDispatch();
const navigate = useNavigate();

useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset);
    }
    if (isSuccess || requestData) {
      toast.success('Api key deleted..')
      window.location.reload();
    }
  }, [requestData, isError, isSuccess, message, navigate, dispatch])

 

const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

const deactivateApikeyData = {
    user_handle:companyId,
    id: id as string
}

dispatch(deactivateRecaptchaApiKey(deactivateApikeyData));
}
return (
    <>
    <UpdateBtn onClick={handleShow}>
            <CIcon icon={cilTrash} className="me-1" />
    </UpdateBtn>
      <Modal show={show} onHide={handleClose}>
      <CForm onSubmit={onSubmit}>
        <Modal.Body>
        <div>
            <p style={{fontSize:'10px'}}>
                <b>Domain name:</b> {domainName}<br></br>
                <b>Api Key:</b> {apikey}<br></br>
            </p>
            <p className='deactivate-api-key-warning-msg'>
               Are you sure you want to delete this reCAPTCHA Api Key? 
            </p>
            
        </div>
        </Modal.Body>
        <Modal.Footer style={{border:'3px #FFF solid'}}>
          <Button variant="default" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading ? <Spinner animation="border" variant="info" />: 
          <Button variant="danger"  type='submit'>
            Yes Delete
          </Button>
         }
        </Modal.Footer>
        </CForm>
      </Modal>
    </>
  );
}

export default  DeactivateAPIKey;