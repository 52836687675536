import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getManageScore } from '../../features/company/manageScoreSlice';
import { gameMinutesDisplay } from '../../utils/constFunctions'
import {
  CCard,
  CCol,
  CRow,
} from '@coreui/react';
import {Table, Spinner } from 'react-bootstrap';

const ManageScore: FC = () =>{
const { company } = useAppSelector((state) => state.auth);
const { resultData, isResultLoading, isResultError, resultMessage } = useAppSelector((state) => state.manageScore);
let { scoreId, handle,title } = useParams();
const dispatch = useAppDispatch();

useEffect(() => {
    if (isResultError) {
      toast.error(resultMessage)
    }
  }, [isResultError,resultMessage])

  useEffect(() => {
    const requestData = {
      // @ts-ignore
      companyId: company.company.user.id,
      id: scoreId,
      handle: handle
    };
    //@ts-ignore
    dispatch(getManageScore(requestData));
  }, [dispatch,company, scoreId, handle] );
  

return (
    <CRow className="justify-content-center">
      <CCol xs={9} className="row">
        <CCard className="col-md-12">
              <h4 className='content-title'>Manage Score</h4>
               <h3 className='title'>{title}</h3>
              {isResultLoading ? <Spinner animation="border" variant="info" /> : ''}
                { !resultData ? '' :
                    <Table striped bordered hover size="sm" responsive="lg">
                        <thead>
                            <tr>
                            <th>User</th>
                            <th>Email</th>
                            <th>Score</th>
                            <th>Minutes</th>
                            </tr>
                        </thead>
                        <tbody style={{border:'1px #FFF solid'}}>
                        {resultData.result && resultData.result.map((key: any, index: any) =>
                            <tr key={index}>
                            <td>{key.first_name} {key.last_name}</td>
                            <td>{key.email}</td>
                            <td height='50'><span className='questionBox'>{key.score}%</span></td>
                            <td>{gameMinutesDisplay(key.game_minutes,key.game_time)}</td>
                            </tr>
                        )}
                            
                        </tbody>
                    </Table>
                }
        </CCard>
      </CCol>
    </CRow>
  )
}

export default ManageScore;
