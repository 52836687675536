import { configureStore } from '@reduxjs/toolkit';
import authTryReducer from '../features/auth/tryCodeBenderSlice';
import authReducer from '../features/auth/authSlice';
import companySignUpReducer from '../features/auth/companySignUpSlice';
import updateCompanyActionReducer from '../features/company/updateCompanySlice';
import AnswercodebenderReducer from '../features/codebender/answerCodebenderSlice';
import startTestReducer from '../features/codebender/startTestSlice';
import finishCodebenderReducer from '../features/codebender/finishCodebenderSlice';
import globalScoreReducer from '../features/codebender/getGlobalScoreSlice';
import createCodeBenderReducer from '../features/company/createCodeBenderSlice';
import verifyAccountReducer from '../features/company/verifyAccountEmailSlice';
import recoverPasswordReducer from '../features/company/recoverPasswordSlice';
import resetPasswordReducer from '../features/company/resetPasswordSlice';
import contactUsReducer from '../features/company/contactUsSlice';
import deleteCodeBenderReducer from '../features/company/deleteCodeBenderSlice';
import updateCodeBenderSettingActionReducer from '../features/company/updateCodeBenderSettingSlice';
import getCodeBenderSettingsReducer from '../features/company/codeBenderSettingsSlice';
import manageScoreReducer from '../features/company/manageScoreSlice';
import createRecaptchaApiKeyReducer from '../features/recaptcha/createRecaptchaApiKeySlice';
import deactivateRecaptchaApiKeyReducer from '../features/recaptcha/deactivateRecaptchaApiKeySlice';
import getRecaptchaApiKeyReducer from '../features/recaptcha/getRecaptchaApiKeySlice';


const store = configureStore({
  reducer: {
    authTry: authTryReducer,
    auth: authReducer,
    authCompanySignUp: companySignUpReducer,
    verifyAccountEmailAction: verifyAccountReducer,
    recoverPasswordAction: recoverPasswordReducer,
    resetPasswordAction: resetPasswordReducer,
    contactUsAction: contactUsReducer,
    startTest: startTestReducer,
    codebenderAnswer: AnswercodebenderReducer,
    codebenderFinish: finishCodebenderReducer,
    codebenderGlobalScore: globalScoreReducer,
    createCodeBenderAction: createCodeBenderReducer,
    getCodeBenderSettings: getCodeBenderSettingsReducer,
    manageScore: manageScoreReducer,
    deleteCodeBenderAction: deleteCodeBenderReducer,
    updateCodeBenderSettingAction: updateCodeBenderSettingActionReducer,
    updateCompanyAction: updateCompanyActionReducer,
    createRecaptchaApiKeyAction: createRecaptchaApiKeyReducer,
    deactivateRecaptchaApiKeyAction: deactivateRecaptchaApiKeyReducer,
    getRecaptchaApiKeysAction: getRecaptchaApiKeyReducer,
  },
});


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store;