import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { deleteCodeBender } from '../../../features/company/deleteCodeBenderSlice';
import { Spinner } from 'react-bootstrap';


const DeleteBtn = styled.span`
    background: transparent;
    border-color: transparent;
    color: #e76767;
    cursor: pointer;
    float: right;
    font-size: 12px;
    margin-top: 10px; 
`;

interface Props{
  id: string; 
  companyId: any;
  cTitle: string;
}

const DeleteCodeBenderSettings: FC<Props> = ({
  id, companyId, cTitle
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.deleteCodeBenderAction
  )
const dispatch = useAppDispatch();
const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess || requestData) {
        setShow(false);
        window.location.reload();
        toast.success('Record deleted..')
    }
  }, [requestData, isError, isSuccess, message, navigate, dispatch])

const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const deleteData = {
        company_id: companyId,
        id: id,
    }
    dispatch(deleteCodeBender(deleteData));
}
  return (
    <>
    <DeleteBtn onClick={handleShow}>
        <CIcon icon={cilTrash} className="me-2" />
         delete.
    </DeleteBtn>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{cTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <span className='color-primary'>
                Are you sure you want to delete this record {cTitle}?!
                {isError ? 
                 <><br /><br /><code className="PropTable-Code-module--cls2--1p8A9 PropTable-Code-module--cls1--2iQb5">{message}</code></>
                 : ''}
                  {isLoading ? <Spinner animation="border" variant="info" />: ''}
            </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button  className='btn-danger' onClick={onSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default  DeleteCodeBenderSettings;