import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import codebenderService from './codebenderService';
import { getGameplayerToken } from '../helper/helper';

interface RequestData {
  company_id: string;
  user_id: string,
  game_settings_id: number;
  number_of_questions:number;
  score: number;
  game_minutes: number;
  game_time: any;
}

interface FinishCodeBenderState {
  requestData?: RequestData | null;
  isFinishError: boolean;
  isFinishSuccess: boolean;
  isFinishLoading: boolean;
  finishMessage: any;
}

const initialState = {
  isFinishError: false,
  isFinishSuccess: false,
  isFinishLoading: false,
  finishMessage: '',
} as FinishCodeBenderState

export const finishCodeBender = createAsyncThunk(
  'codebender/finish',
  async (finishData: RequestData, thunkAPI) => {
    try {
      const token =  getGameplayerToken(thunkAPI);
      //@ts-ignore
      finishData = { ...finishData, user: thunkAPI.getState().authTry.user};
      return await codebenderService.finishCodeBender(finishData,token);
    } catch (error: any) {
      const finishMessage =
        (error.response &&
          error.response.data &&
          error.response.data.finishMessage) ||
        error.finishMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(finishMessage)
    }
  }
)

export const finishCodebenderSlice = createSlice({
  name: 'codebenderFinish',
  initialState,
  reducers: {
    reset: (state) => {
      state.isFinishLoading = false
      state.isFinishSuccess = false
      state.isFinishError = false
      state.finishMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(finishCodeBender.pending, (state) => {
        state.isFinishLoading = true
      })
      .addCase(finishCodeBender.fulfilled, (state, action) => {
        state.isFinishLoading = false
        state.isFinishSuccess = true
        state.finishMessage = action.payload.message
      })
      .addCase(finishCodeBender.rejected, (state, action) => {
        state.isFinishLoading = false
        state.isFinishError = true
        state.finishMessage = action.payload
      })
  },
})

export const { reset } = finishCodebenderSlice.actions;
export default finishCodebenderSlice.reducer;