import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import startCodeBenderService from './startCodeBenderService';

//@ts-ignore
const user = JSON.parse(localStorage.getItem('userData'))

interface RequestData {
  firstName: string;
  lastName: string;
  email: string;
  companyId: string;
  gameSetttingsId: string | undefined;
}

interface TryCodeBenderState {
  user?: RequestData | null | any,
  isError: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  message: any,
}

const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as TryCodeBenderState

export const tryCodeBender = createAsyncThunk('auth/tryCodeBender', async (user: RequestData, thunkAPI) => {
    try {
      user = { ...user};
      return await startCodeBenderService.startCodeBender(user)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })
export const authSlice = createSlice({
  name: 'authTry',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(tryCodeBender.pending, (state) => {
        state.isLoading = true
      })
      .addCase(tryCodeBender.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(tryCodeBender.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
  }

})

export const { reset } = authSlice.actions;
export default authSlice.reducer;