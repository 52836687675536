import React, {FC} from 'react';

interface Props{
  alertMessage: any;
}
const GameActionAlert: FC<Props> = ({alertMessage}) =>{
   
  return (
    <>
    <div className="container">
      <div className="row">
          <h3 className="animate-charcter">{alertMessage}</h3>
      </div>
    </div>
    </>
  );
}

export default  GameActionAlert;