import React, { FC } from 'react';
import GameTimer from '../play-timer/gameTimer';
import FinishGame from "../SubShare/modal/FinishGame";
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import GamePlayInstruction from '../SubShare/modal/GamePlayInstruction';

interface Props {
  gameSetttingsId: number;
  company?: any;
  user: any; 
  question: any;
  instuction?: string;
  score?: number;
  gameMinutes: 10;
  questionNumber: number,
  totalQuestionNumber: number;
  isQuestionComplete: boolean;
}

export const PlayHeader: FC<Props> = ({ company, user, question,instuction,score, gameMinutes, gameSetttingsId,questionNumber,totalQuestionNumber, isQuestionComplete }) => {

  return (
    <CContainer>
      <CRow>
        <CCol lg="6" className="bg-default py-3">
          <p className='user-display-name'>Hi {user.user.first_name} @ <label>{company.company_name}</label></p>
          <p className='user-display-name text-secondary'>Question {`${questionNumber}`} of {`${totalQuestionNumber}`}</p>
        </CCol>
        <CCol lg="6" className="bg-default py-3" style={{textAlign:'right'}}>
            Score:<label className='score-text'>{score}</label>
            <label className='timer-text'>
            <GameTimer 
                  gameMinutes={gameMinutes} 
                  gameSetttingsId={gameSetttingsId}
                  user={user}
                  company={company}
                  score={score}
                  isQuestionComplete={isQuestionComplete}
                  numberOfQuestions={totalQuestionNumber}
              />
            </label>
            <FinishGame 
                  firstName={user.user.first_name}
                  gameTimer={user.gameTimer}
                  userId = {user.user.id}
                  companyId = {company.id}
                  score = {score}
                  gameSetttingsId = {gameSetttingsId}
                  gameMinutes = {gameMinutes}
                  numberOfQuestions={totalQuestionNumber}
              />
        </CCol>
      </CRow>
      <CRow>
        <p className='btn-game-play-instruction'>
          <GamePlayInstruction />
        </p>
        <h1 style={{marginTop:'90px'}}>{instuction}</h1>
        <br/> <br/> <br/> <br/> 
        <h1>What is 
          {/* {question.question} */}
        {Object.keys(question).map((key, index) =>
            <span className='questionBox' key={index}>{question[key]}</span>
          )}
        </h1>
      </CRow>
    </CContainer>
  );
}