import React from 'react';
import {
    CRow,
  } from '@coreui/react';

const RecaptchaNav = () => {

    return (
        <CRow>
            <div className="recaptcha-nav">
            <p>Samples</p>
             <ul id="recaptcha-side-nav">
                <li><a href='/nodejs-sample'>Node.Js</a></li>
                <li><a href='/java-sample'>Java</a></li>
                <li><a href='/python-sample'>Python</a></li>
                <li><a href='/c-sharp-sample'>C#</a></li>
                <li><a href='/ruby-sample'>Ruby</a></li>
                <li><a href='/php-sample'>PHP</a></li>
             </ul>
            </div>
        </CRow>
        )
    }
export default RecaptchaNav;
