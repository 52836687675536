import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import companyService from './companyService';
import { getCompanyToken } from '../helper/helper';


interface RequestData {
  company_id: string;
  title: string,
  instructions: string 
  question_type: string;
  wordLength: string;
  numberOfQuestion: string;
  test_time: string;
  expiry_date: string;
}

interface CreateCodeBenderState {
  requestData?: RequestData | null,
  isError: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  message: any,
  }

const initialState = {
  requestData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as CreateCodeBenderState

export const updateCodeBenderSetting = createAsyncThunk('codebender/updateinstruction', async (requestData: RequestData, thunkAPI) => {
    try {
      const token =  getCompanyToken(thunkAPI);

      requestData = { ...requestData};
      return await companyService.updateCodeBenderSettings(requestData,token)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })

export const updateBenderSlice = createSlice({
  name: 'updateCodeBenderSettingAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(updateCodeBenderSetting.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCodeBenderSetting.fulfilled, (state, action) => {
        if(action.payload.result.status === 500){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.result.errorMessage
            state.requestData = null
        }else{
            state.isLoading = false
            state.isSuccess = true
            state.requestData = action.payload
        }
      })
      .addCase(updateCodeBenderSetting.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = null
      })
  }

})

export const { reset } = updateBenderSlice.actions;
export default updateBenderSlice.reducer;