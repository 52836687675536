import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import recaptchaService from './recaptchaService';
import { getCompanyToken } from '../helper/helper';


interface RequestData {
    user_handle: string;
    domain_name: string;
}

interface CreateRecaptchaState {
  requestData: RequestData | null;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: any;
}


const initialState = {
  requestData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as CreateRecaptchaState

export const createRecaptchaApiKey = createAsyncThunk('codebender/create/recaptchaapikey', async (requestData: RequestData, thunkAPI) => {
    try {
      const token =  getCompanyToken(thunkAPI);
      requestData = { ...requestData};
      return await recaptchaService.createRCApikey(requestData,token)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })

export const createRecaptchaApiKeySlice = createSlice({
  name: 'createRecaptchaApiKeyAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(createRecaptchaApiKey.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createRecaptchaApiKey.fulfilled, (state, action) => {
        if(action.payload.status === 500){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.errorMessage
            state.requestData = null
        }else{
          if(action.payload.isDomainName === 409){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.errorMessage
            state.requestData = null
          }else{
            state.isLoading = false
            state.isSuccess = true
            state.requestData = action.payload
          }
            
        }
      })
      .addCase(createRecaptchaApiKey.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = null
      })
  }

})

export const { reset } = createRecaptchaApiKeySlice.actions;
export default createRecaptchaApiKeySlice.reducer;