import React, {FC} from 'react';
import { useAppDispatch } from '../../../hooks/useTypedSelector';
import { Modal  } from 'react-bootstrap';
import { finishCodeBender } from '../../../features/codebender/finishCodebenderSlice';
import {currentGameCompleted, gameCompleteData} from "../../../utils/constFunctions";

interface Props{
  firstName: string;
  gameTimer: any;
  companyId: string;
  userId: string; 
  score?: number; 
  gameSetttingsId: number;
  gameMinutes: number;
  numberOfQuestions: number;
}

const FinishGame: FC<Props> = ({
  firstName,gameTimer,companyId,userId,score,gameSetttingsId,gameMinutes,numberOfQuestions
}) => {

  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    const taggle = isShow ? false : true;
    return invokeModal(taggle)
  }

const dispatch = useAppDispatch();

const finishCurrentGame = () => {
  const finishData = gameCompleteData(numberOfQuestions,userId,companyId,gameSetttingsId,score,gameMinutes);
  dispatch(finishCodeBender(finishData));
  currentGameCompleted(firstName,gameTimer,numberOfQuestions,score,gameMinutes,localStorage.getItem("currentGameTimer"));
}

return (
      <>
      <button type="button" style={{marginLeft:"50px", fontSize:'12px'}} className="btn btn-light"
        onClick={initModal}>
        End Game
      </button>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to end this game?
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={initModal}>
            Close
          </button>
          <button className="btn btn-success" onClick={()=>finishCurrentGame()}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FinishGame;