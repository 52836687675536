import  React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { answerCodeBender } from '../../features/codebender/answerCodebenderSlice';
import  {PlayHeader} from './PlayHeader';
import {currentGameCompleted} from "../../utils/constFunctions";
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import GameActionAlert from '../SubShare/GameActionAlert';

const PlayCodeBender:FC = ()=>{
 const [ isMessageAlertVisible, setisMessageAlertVisible ] = useState(false);
 const { user } = useAppSelector((state) => state.authTry);
 const dispatch = useAppDispatch();
 const { answerMessage, answerScore } = useAppSelector((state) => state.codebenderAnswer);

 const [question, setQuestion] = useState("");
 const storedQuestionNumber = Number(localStorage.getItem('nextQestion'));
 const nextQestionRef = useRef(
   Number.isInteger(storedQuestionNumber) ? storedQuestionNumber : -1
 );
const [isQuestionComplete, setIsQuestionComplete] = useState(false);
 const [formData, setFormData] = useState({
  answer: ''
});
const [company] = useState(user.company[0]);
const [gameTimer] = useState(user.gameTimer);
const { answer } = formData;

const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^\w\s]/gi, '');
}

const checkIfNumber = (char: string) =>{

  if(parseInt(char) > 0){
      return true;
  }
  return false;
}
const validateCurrentQuestion = (stringQuestion: string | string[]) =>{
 
  if(!checkIfNumber(stringQuestion[0])){ //If question is String
    return  question.toString().replace(/,/g, '');
  }
  return question.toString().replace(/,/g, '-');
}

const onChange = (e: { target: { name: any; value: string; }; }) => {
  setFormData((prevState) => ({
    ...prevState,
    [e.target.name]: e.target.value.toUpperCase(),
  }))
}
const getNextQuestion = useCallback(() => {
  const splitQuestion = (stringChar: string) =>{
    let regExp= /^(?=.*[a-zA-Z])(?=.*[0-9])/;
    const check = regExp.test(removeSpecialCharacters(stringChar));
    if(check){
      return stringChar.split("-");
    }

    if(stringChar.toLowerCase() !== stringChar.toUpperCase()){
      return stringChar.split("");
    }
    return stringChar.split("-");
  }

  localStorage.setItem('nextQestion', String(nextQestionRef.current));
  if(nextQestionRef.current < user.question.length){
    //@ts-ignore
    setQuestion(splitQuestion(user.question[nextQestionRef.current].question))
    nextQestionRef.current += 1;
  }
}, [setQuestion, user.question]);

const onSubmit = (e: { preventDefault: () => void; }) => {
  e.preventDefault();
  const userAnswer = {
    question: validateCurrentQuestion(question),
    answer
  }
  dispatch(answerCodeBender(userAnswer));
 
  setFormData({
    answer : '',
  });
  handleMessageAlert();
  if(nextQestionRef.current === user.question.length){
    setIsQuestionComplete(true)
  }
  getNextQuestion();
}

if(!localStorage.getItem("stateUserGameData")){
  currentGameCompleted();
}

const handleMessageAlert = () => {
  setisMessageAlertVisible(true);
  setTimeout(() => {
     setisMessageAlertVisible(false);
  }, 3000);

}
useEffect(() => {
  getNextQuestion();
}, [getNextQuestion]);

return (
  <CContainer className='section'>
    <CRow>
      <PlayHeader 
        gameSetttingsId={user.gameSetttingsId}
        company={company}
        user={user.user} 
        question={question} 
        score={answerScore}
        instuction={user.instructions}
        gameMinutes={gameTimer}
        questionNumber={nextQestionRef.current}
        totalQuestionNumber={user.question.length}
        isQuestionComplete={isQuestionComplete}
      />
    </CRow>
    <CRow>
    <CCol lg="4" className="bg-default py-3"></CCol>
    <CCol lg="4" className="bg-default py-3">
      {/* @ts-ignore */}
      <form xs={6} onSubmit={onSubmit} style={{marginTop:'20px'}}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='answer'
                    name='answer'
                    value={answer}
                    placeholder='Answer'
                    onChange={onChange}
                  />
                </div>
              <div className='form-group'>
                <button className='btn btn-block btn btn-success' type='submit'>
                    Submit
                </button>
              </div>
              {isMessageAlertVisible && 
                <GameActionAlert alertMessage={answerMessage} />
              } 
      </form>
      </CCol>
    </CRow>
  </CContainer>
  )
}

export default PlayCodeBender