import React, { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { Table, Spinner, Pagination } from 'react-bootstrap';
import { getGlobalScore } from '../../features/codebender/getGlobalScoreSlice';
import { gameMinutesDisplay, testGrade, calculatePoints } from '../../utils/constFunctions';
import { CCard, CCardBody, CCol, CRow } from '@coreui/react';

const GlobalScore: FC = () => {
  const { resultData, isResultLoading } = useAppSelector((state) => state.codebenderGlobalScore);
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    dispatch(getGlobalScore());
  }, [dispatch]);

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardBody>
          <h4>Global Score</h4>
                {isResultLoading ? <Spinner animation="border" variant="info" /> : ''}
                {!resultData ? (
                  ''
                ) : (
                  <>
                    <Table className="table-container" striped bordered hover size="sm" responsive="lg">
                      <thead>
                        <tr>
                          <th style={{textAlign:'center'}}>User</th>
                          <th>Points</th>
                          <th>Score</th>
                          <th>Minutes</th>
                        </tr>
                      </thead>
                      <tbody style={{ border: '1px #FFF solid', textAlign:'left'}}>
                        {((resultData as any).result || [])
                          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                          .map((key: any, index: string) => (
                            <tr key={index}>
                              <td>
                                {key.first_name} {key.last_name}
                              </td>
                              <td>
                                {calculatePoints(key.number_of_questions, key.score,key.game_time)}
                              </td>
                              <td height="50">
                                <span className="questionBox">{testGrade(key.number_of_questions, key.score)}%</span>
                              </td>
                              <td>{gameMinutesDisplay(key.game_minutes, key.game_time)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Pagination>
                      <Pagination.Prev
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      />
                      <Pagination.Next
                        disabled={currentPage === Math.ceil(resultData.length / itemsPerPage)}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      />
                    </Pagination>
                  </>
                )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default GlobalScore;
