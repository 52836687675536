import React, { FC, useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { tryCodeBender, reset } from '../../features/auth/tryCodeBenderSlice';

interface Props{
  companyId: string;
  gameSetttingsId?: string; 
}

const StartTestForm: FC<Props> = ({
  companyId, gameSetttingsId
}) => {

    const [location, setCountryValue] = useState('');
    const countrys = useMemo(() => countryList().getData(), []);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
      });
const { firstName, lastName, email} = formData;
const { user, isError, isSuccess, message } = useAppSelector(
    (state) => state.authTry
  )
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset);
    }
    if (user || isSuccess) {
        window.location.href = '/start-code-bender';
      }
    
  }, [user, isError, isSuccess, message, dispatch])


const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
}
const changeHandler = (location: any) => {
setCountryValue(location)
}
const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const userData = {
        firstName,
        lastName,
        email,
        companyId: companyId,
        gameSetttingsId: gameSetttingsId
      }
dispatch(tryCodeBender(userData));
setFormData({
    firstName: '', 
    lastName: '',
    email : '',
});
}
return (
      <>
        <h4 className='text-center'>Sign up before you start</h4>
            <form onSubmit={onSubmit}>
                <div className='row' >
                    <div className='col-md-6'>
                        <label>First Name</label>
                        <div className='form-group'>
                                <input
                                type='text'
                                className='form-control'
                                id='firstName'
                                name='firstName'
                                value={firstName}
                                placeholder='First Name'
                                onChange={onChange}
                                required
                             />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <label>Last Name</label>
                        <div className='form-group'>
                                <input
                                type='text'
                                className='form-control'
                                id='lastName'
                                name='lastName'
                                value={lastName}
                                placeholder='Last Name'
                                onChange={onChange}
                                required
                             />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label>Email</label>
                        <div className='form-group'>
                                <input
                                    type='email'
                                    className='form-control'
                                    id='email'
                                    name='email'
                                    value={email}
                                    placeholder='Email'
                                    onChange={onChange}
                                    required
                                />
                        </div>
                        <div className='form-group'>
                         <label>Location</label>
                        <Select 
                            id='location'
                            // @ts-ignore
                            options={countrys} 
                            value={location} 
                            onChange={changeHandler} 
                            required
                        />
                        </div>
                        <div className='form-group'>
                            <button className='btn btn-block btn-success' type='submit' style={{color:'#FFF'}}>
                                   Start Test
                            </button>
                        </div>
                    </div>
                 </div>
            </form>
      </>
    )
  }
  
  export default StartTestForm;