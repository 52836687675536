import React, { FC, useState } from 'react';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CIcon from '@coreui/icons-react';
import { cilCreditCard } from '@coreui/icons';


const UpdateBtn = styled.span`
    background: #ebedef;
    border: 1px #6d87dd solid;
    border-radius: 4px;
    padding: 3px 10px;
    color: #6d87dd;
    cursor: pointer;
    float: left;
    font-size: 12px; 
    margin-bottom: -32px;
    margin-top: -22px;
`;

const GamePlayInstruction: FC = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <UpdateBtn onClick={handleShow}>
        <CIcon icon={cilCreditCard} className="me-2" />
          Instructions
    </UpdateBtn>
      <Modal show={show} onHide={handleClose}>
       
        <Modal.Body>
            <div>
                <p className='create-api-key-warning-msg'>
                If the letters of the alphabet are assigned numerical values from 1 to 26, with A being 1, B being 2, and so on until Z being 26, and the numbers from 1 to 26 are assigned letter values with 1 being A, 2 being B, and so on until 26 being Z, what is the letter representation of the number DAD? The answer will be 414. 
                </p>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default  GamePlayInstruction;