import axios from 'axios'
import { config } from '../../common/config';

const API_URL = config.baseUrl.default;

const startTestBender = async (payload: { id: any; handle: any; }) => {
  const {id,handle} = payload;
  const response = await axios.get(API_URL + `start-test/${id}/${handle}`);
  return response.data;
}
const answerCodeBender = async (userAnswer: object,token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + 'check-answer', userAnswer, config);
  return response.data;
}

const finishCodeBender = async (finishData: object,token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  if(localStorage.getItem("currentGameTimer")){
    const response = await axios.post(API_URL + 'finish-user-game', finishData, config);
    return response.data;
  }
  return true;
}

const getGlobalScore = async () => {

  const response = await axios.get(API_URL + 'get-all-scores');
  return response.data;
}

const userService = {
  answerCodeBender,
  finishCodeBender,
  getGlobalScore,
  startTestBender
}

export default userService