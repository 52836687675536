import React, {FC, useState} from 'react';
import { NavLink } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import {
  CContainer,
  CHeader,
  CHeaderNav,
  CNavLink,
  CNavItem,
  CHeaderDivider,
  CHeaderToggler,
} from '@coreui/react';
import { 
  cilMenu,
  cilFile,
  cilTask,} from '@coreui/icons';
import { AppHeaderDropdown } from './index';
import {  checkUserType } from '../../utils/constFunctions';
import {  ReCaptchaTopNav } from './index';
import useRcNav from '../../hooks/rcNav';

interface Props{
  auth: any;
}
const AuthHeader: FC<Props> = ({
    auth
  }) => {
    
const userType = checkUserType(auth);
const [isMenuOpen, setMenuOpen] = useState(false);

const toggleMenu = () => {
  setMenuOpen(!isMenuOpen);
};

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1 d-md-none" onClick={toggleMenu}>
           <CNavLink to="/" component={NavLink}>
              <label className='thetitle'>TheLast<span>IQ</span>Test</label>
            </CNavLink>
        </CHeaderToggler>
   
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
          
          { auth? 
            <CNavLink to="/company-dashboard" component={NavLink}>
               <label className='thetitle'>TheLast<span>IQ</span>Test</label>
            </CNavLink>
            : 
            <CNavLink to="/" component={NavLink}>
               <label className='thetitle'>TheLast<span>IQ</span>Test</label>
            </CNavLink>
          }
          </CNavItem>
          <CNavItem>
            <CNavLink href="/re-captcha">
                <span  className='menu-list'>Re-Captcha</span>
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="/glabal-score">
              <CIcon icon={cilFile} className="me-2" />
                <span  className='menu-list'>Global score</span>
            </CNavLink>
          </CNavItem>
          { (auth && userType === "company")? 
            <>
            <CNavItem>
            <CNavLink href="/manage-codebender">
            <CIcon icon={cilMenu} className="me-2" />
              <span  className='menu-list'>Manage IQtest</span>
            </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink href="/create-codebender">
              <CIcon icon={cilTask} className="me-2" />
                <span  className='menu-list'>Create IQtest</span>
              </CNavLink>
            </CNavItem>
            </>
          : '' }
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown auth={auth} userType={userType} />
        </CHeaderNav>
      </CContainer>
      {useRcNav() === true ? 
       <>
       <CHeaderDivider />
        <CContainer fluid>
          <ReCaptchaTopNav />
        </CContainer>
      </>
      : ''}
    </CHeader>
  )
}

export default AuthHeader;