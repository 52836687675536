import React from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppFooter } from './components/index';
import AppRoutes from './AppRoutes';
import {  AuthHeader, DefaultHeader } from './components/header/index';
import '@coreui/coreui/dist/css/coreui.min.css';

function App() {
// @ts-expect-error
  const checkAuth = JSON.parse(localStorage.getItem('companyData'));


  return (
    <>
    <Router>
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <>
          { checkAuth ? <AuthHeader auth={checkAuth} /> : <DefaultHeader /> }
        </>
        <div className="body flex-grow-1 px-3">
          <AppRoutes />
        </div>
        <AppFooter />
      </div>
    </div>
    </Router>
    <ToastContainer />
  </>
  );
}

export default App;
