import React, {FC} from 'react';
import { Props } from 'react-select';
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const CSharpApiSampleCode: FC<Props> = () => {
const codeSampleGetQuestion = `
    /**
    * Retrieves a ReCaptcha question of the specified type and word length.
    *
    * @param {string} type The type of ReCaptcha question to retrieve. 
    * type: Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX.
    * @param {integer} wordLength The length of the ReCaptcha word to retrieve.
    * @param {string} apiKey The API key obtained from https://www.thelastiqtest.com/re-captcha/apikey.
    * @return {Promise} A promise that resolves with the ReCaptcha question and answer.
    */

    using System;
    using System.Net;
    using Newtonsoft.Json;
    
    class ReCaptchaAPI {
    public void main() {
    string type = "characters";
    int wordLength = 5;
    string apikey = "fadshjgckxvl536789ewdgv";
    // Build the API endpoint URL
    string endpoint = "https://www.thelastiqtest.com/v1/api/code-bender/re-captcha/" + type + "/" + wordLength + "/" + apikey;

    // Make the GET request to the API
    using (WebClient client = new WebClient()) {
        string jsonResponse = client.DownloadString(endpoint);

        // Parse the JSON response
        dynamic jsonData = JsonConvert.DeserializeObject(jsonResponse);

        // Get the ReCaptcha question from the response
        string question = jsonData.question;

        // Print the question to the console
        Console.WriteLine("ReCaptcha Question: " + question);
    } 

}
`;
const codeSampleAnswerQuestion = `
/**
 * Submits an answer to a ReCaptcha question.
 *
 * @param {string} question The ReCaptcha question.
 * @param {string} answer The answer to the ReCaptcha question.
 * @param {string} apiKey The API key obtained from https://www.thelastiqtest.com/re-captcha/apikey.
 * @return {Promise} A promise that resolves with a boolean indicating whether the answer was correct or not.
 */

//Example 1


using System;
using System.Net.Http;
using Newtonsoft.Json;

public class CodeBenderAPI
{
    public static async Task<bool> SubmitReCaptchaAnswer(string question, string answer, string apiKey)
    {
        using (var client = new HttpClient())
        {
            var json = JsonConvert.SerializeObject(new { question, answer, apiKey });
            var content = new StringContent(json, Encoding.UTF8, "application/json");

            var response = await client.PostAsync("https://www.thelastiqtest.com/v1/api/code-bender/answer-re-captcha", content);

            var responseString = await response.Content.ReadAsStringAsync();
            var responseData = JsonConvert.DeserializeObject<dynamic>(responseString);

            return responseData.correct;
        }
    }
}

//You can then call this method by passing in the question, answer and apiKey as arguments, for example:

bool isAnswerCorrect = await CodeBenderAPI.SubmitReCaptchaAnswer("What is DAD", "414", "your_api_key");

//Example 2

using System;
using System.Net.Http;
using System.Net.Http.Headers;
using System.Threading.Tasks;

public class CodeBender
{
private const string Endpoint = "https://www.thelastiqtest.com/v1/api/code-bender/answer-re-captcha";
private const string ApiKey = "YOUR_API_KEY_HERE";

Copy code
public async Task<bool> SubmitReCaptchaAnswer(string question, string answer)
{
    using (var client = new HttpClient())
    {
        // Set the endpoint and api key for the request
        client.BaseAddress = new Uri(Endpoint);
        client.DefaultRequestHeaders.Add("apikey", ApiKey);

        // Create the form data to send with the request
        var formData = new FormUrlEncodedContent(new[]
        {
            new KeyValuePair<string, string>("question", question),
            new KeyValuePair<string, string>("answer", answer)
        });

        // Send the post request and get the response
        var response = await client.PostAsync(Endpoint, formData);
        var responseString = await response.Content.ReadAsStringAsync();

        // Parse the response string to check if the answer was correct
        bool isCorrect = bool.Parse(responseString);
        return isCorrect;
    }
}
}

// Example usage
var codeBender = new CodeBender();
bool answerCorrect = await codeBender.SubmitReCaptchaAnswer("What is DAD?", "414");
Console.WriteLine(answerCorrect ? "Correct!" : "Incorrect.");
`;

    return(
       <>
            <h6>Get Question</h6>
            <SyntaxHighlighter language="java" style={monokaiSublime}>
                {codeSampleGetQuestion}
            </SyntaxHighlighter>
            <h6>Answer Question</h6>
            <SyntaxHighlighter language="java" style={monokaiSublime}>
                {codeSampleAnswerQuestion}
            </SyntaxHighlighter>
       </>
    )
}