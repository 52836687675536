import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import codebenderService from './codebenderService';


interface GloBalScoreState{
  isResultError: boolean,
  isResultSuccess: boolean,
  isResultLoading: boolean,
  resultMessage: any,
  resultData: []
}

const initialState = {
  isResultError: false,
  isResultSuccess: false,
  isResultLoading: false,
  resultMessage: '',
  resultData: []
} as GloBalScoreState


export const getGlobalScore = createAsyncThunk(
  'codebender/globalscore',
  async (data,thunkAPI) => {
    try {
      return await codebenderService.getGlobalScore();
    } catch (error: any) {
      const resultMessage =
        (error.response &&
          error.response.data &&
          error.response.data.resultMessage) ||
        error.resultMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(resultMessage)
    }
  }
)

export const getGlobalScoreSlice = createSlice({
  name: 'codebenderGlobalScore',
  initialState,
  reducers: {
    reset: (state) => {
      state.isResultLoading = false
      state.isResultSuccess = false
      state.isResultError = false
      state.resultMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalScore.pending, (state) => {
        state.isResultLoading = true
      })
      .addCase(getGlobalScore.fulfilled, (state, action) => {
        state.isResultLoading = false
        state.isResultSuccess = true
        state.resultData = action.payload
        state.resultMessage = action.payload.message
      })
      .addCase(getGlobalScore.rejected, (state, action) => {
        state.isResultLoading = false
        state.isResultError = true
        state.resultMessage = action.payload
      })
  },
})

export const { reset } = getGlobalScoreSlice.actions;
export default getGlobalScoreSlice.reducer;