// @ts-nocheck
import { passwordStrength } from 'check-password-strength';

export const gameCompleteData: any = (numberOfQuestions:number, userId?:number, companyId?: number, gameSetttingsId?:number, score?:number, gameMinutes?: number) => {
    
    const finishGameData = {
        company_id: companyId,
        user_id: userId,
        game_settings_id: gameSetttingsId,
        number_of_questions:numberOfQuestions,
        score: score,
        game_minutes: gameMinutes,
        game_time: localStorage.getItem("currentGameTimer"),
      }

      return finishGameData;
}

export const testGrade: any = (numberOfQuestions:number, score: number) =>{
    const defaultScore = 5;
    const maximumMarks = numberOfQuestions * defaultScore;
    const PercentageMarks = (score/maximumMarks) * 100;

    return PercentageMarks.toFixed(1); 

}
export const calculatePoints = (numberOfQuestions:number, gameScore:number, gameTimeCompleted:string) => {
    const correctAnswers = gameScore/5;
    const defaultScore = 1000; // Base points for each correct answer
    const maxResponseTime = 5 * 60; // Maximum time allowed in seconds (5 minutes)
  
    const gameCompleteTime = evaluateGameCompleTime(gameTimeCompleted);
    const timeTaken = gameCompleteTime.hours * 3600 + gameCompleteTime.minutes * 60 + gameCompleteTime.seconds;
  
    const timeBonus = Math.max(maxResponseTime - timeTaken, 0) / maxResponseTime;
    const maximumScore = numberOfQuestions * defaultScore;
    const score = correctAnswers * defaultScore;
    const totalPoints = Math.floor(score + timeBonus * maximumScore);
    return numberWithCommas(gameScore > 0 ? totalPoints: 0);
  };
export const  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const currentGameCompleted: any = (firstName: string, gameTimer: any, totalQuestions?: number, score?: number, gameMinutes?: number, gameTime?: object)=>{

    if(score || gameTime){

        const finalGameTime = JSON.stringify(gameTime);
        const finalTimeData = finalGameTime.replace(/\\/g, "");
        const resultTime = finalTimeData.substring(1, finalTimeData.length-1);
        const getResultTime: object = JSON.parse(resultTime);

        localStorage.setItem(
            "finalGameScore",
            JSON.stringify( { firstName:firstName, gameTimer:gameTimer, totalQuestions:totalQuestions, score: score, gameMinutes, ...getResultTime} )
        );
    }

    localStorage.removeItem('stateUserGameData');
    localStorage.removeItem('currentGameTimer');
    localStorage.removeItem('gameTimer');
    localStorage.removeItem('isNavigatePage');
    window.location.href = "/game-complete";
}

export const checkUserType = (auth: object) => {
    
    if(auth.question || auth.gameTimer || auth.gameSetttingsId){
        return 'gamePlayer'
    }
    return 'company';
}

export const gameMinutesDisplay: any = (gameMinutes: number, gameTimeCompleted: string) => {
    if(!gameTimeCompleted) return '';
    const getResultTime = evaluateGameCompleTime(gameTimeCompleted);
    return  getResultTime.minutes + ' of ' + gameMinutes + ' mins';
}
export const evaluateGameCompleTime = (gameCompleteTime) =>{
    const finalGameTime = JSON.stringify(gameCompleteTime);
    const finalTimeData = finalGameTime.replace(/\\/g, "");
    const resultTime = finalTimeData.substring(1, finalTimeData.length-1);
    return JSON.parse(resultTime);
}
export const updateCompanyLocalStorageInfo = (companyInfo: any,newCompanyInfo: any) => {
    companyInfo.company.user = newCompanyInfo.company.user;
    const updatedCompanyInfo = JSON.stringify(companyInfo);
    localStorage.setItem("companyData", updatedCompanyInfo);
}

export const passwordStrengthValidation = (password: any) => {
    const validate = passwordStrength(password).value;
    if(validate === 'Too weak' || validate === 'Weak'){
        return {
            status: false,
            value: validate,
            className: 'text-danger',
            message: `Your password is VV, please use another passsword. try to include special characters`
        }
    }
    return {
        status: true,
        message: `Your password is <b className="text-success">${validate}<b>`
    }

}
export function checkCompanyAndUserAuth() {

  return {
        company: localStorage.getItem("companyData"),
        user: localStorage.getItem("userData"),
        gameTimer: localStorage.getItem('currentGameTimer') ? localStorage.getItem('gameTimer'): null,
      }
}
export const isExpired = (date: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date < today;
}
export const reCaptchaPageUrls = () => {
    
    return ["recaptcha", "apireference",'apikey',
            "nodejssample","javasample","csharpsample",
            "phpsample","pythonsample","rubysample"];
}