import React, { Component } from 'react';
import {Routes, Route } from 'react-router-dom';
import GameComplete from './components/play-code-bender/GameComplete';
import GlobalScore from './components/play-code-bender/GlobalScore';
import {ReCaptcha} from './components/re-captcha/ReCaptcha';
import APIKey from './components/re-captcha/APIKey';
import {APIRefrence} from './components/re-captcha/APIRefrence';
import Home from './pages/Home';
import PlayCodeBender from './components/play-code-bender/PlayCodeBender';
import StartCompany from './pages/StartCompany';
import CompanySignUp from './components/company/CompanySignUp';
import VerifyEmail from './components/company/VerifyEmail';
import CompanySettings from './components/company/CompanySettings';
// import CompanyDashboard from './components/company/CompanyDashboard';
import CreateNewCodeBender from './components/company/CreateNewCodeBender';
import ManageCodeBender from './components/company/ManageCodeBender';
import ManageScore from './components/company/ManageScore';
import StartTest from './components/test/StartTest';
import {JavscriptApiSample,
        JavaApiSample,
        CSharpApiSample,
        PHPApiSample,
        PythonApiSample,
        RubyApiSample
      } from './components/re-captcha/api-ref-sample/samples';
import RecoverPassword from './components/company/passwordrecovery/RecoverPassword';
import ResetPassword from './components/company/passwordrecovery/ResetPassword';
import ContactUs from './components/about/ContactUs';


class AppRoutes extends Component {
  render () {
    return (
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/start-bending-code/:gameSetttingsId/:gameSetttingsHandle' element={<StartTest />} />
            <Route path='/start-code-bender' element={<PlayCodeBender />} />
            <Route path='/start-company' element={<StartCompany />} />
            <Route path='/game-complete' element={<GameComplete />} />
            <Route path='/glabal-score' element={<GlobalScore />} />
            <Route path='/re-captcha' element={<ReCaptcha />} />
            <Route path='/apiKey' element={<APIKey />} />
            <Route path='/api-reference' element={<APIRefrence />} />
            <Route path='/company-signup' element={<CompanySignUp />} />
            <Route path='/verify-email/:token' element={<VerifyEmail />} />
            <Route path='/profile-settings' element={<CompanySettings />} />
            {/* <Route path='/company-dashboard' element={<CompanyDashboard />} /> */}
            <Route path='/company-dashboard' element={<ManageCodeBender />} />
            <Route path='/recover-password' element={<RecoverPassword />} />
            <Route path='/email-password-reset/:email/:token' element={<ResetPassword />} />
            <Route path='/create-codebender' element={<CreateNewCodeBender />} />
            <Route path='/manage-codebender' element={<ManageCodeBender />} />
            <Route path='/manage-score/:scoreId/:handle/:title' element={<ManageScore />} />
            <Route path='/nodejs-sample' element={<JavscriptApiSample />} />
            <Route path='/java-sample' element={<JavaApiSample />} />
            <Route path='/c-sharp-sample' element={<CSharpApiSample />} />
            <Route path='/php-sample' element={<PHPApiSample />} />
            <Route path='/python-sample' element={<PythonApiSample />} />
            <Route path='/ruby-sample' element={<RubyApiSample />} />
            <Route path='/contact-us' element={<ContactUs />} />
          </Routes>
    );
  }
}

export default AppRoutes;