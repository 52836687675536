import axios from 'axios'
import { config } from '../../common/config';

const API_URL = config.baseUrl.default;
 
const createBender = async (requestData: any,token: string) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    const response = await axios.post(API_URL + 'create-codebender', requestData, config)
    return response.data;
} 
const updateCompanyInfo = async (requestData: any,token: string) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  const response = await axios.put(API_URL + 'update-company-info', requestData, config);
  return response.data;
}
const updatePassword = async (requestData: any,token: string) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  const response = await axios.put(API_URL + 'update-company-password', requestData, config);
  return response.data;
}
const updateCodeBenderSettings = async (requestData: any, token: string) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  const response = await axios.put(API_URL + 'update-codebender-settings', requestData, config)
  return response.data;
}
const deleteBender = async (requestData: any, token: string) => {
  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  const response = await axios.post(API_URL + 'delete-codebender', requestData, config)
  return response.data;
}
const verifyEmail = async (requestData: any) => {

  const response = await axios.post(API_URL + `verify-email/${requestData}`)
  return response.data;
}
const recoverPassword = async (requestData: any) => {

  const response = await axios.post(API_URL + 'send-reset-password', requestData)
  return response.data;
}
const resetPassword = async (requestData: any) => {

  const response = await axios.post(API_URL + 'reset-password', requestData)
  return response.data;
}
const getCodeBenderSettings = async (companyId: string,token: string) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  
  const response = await axios.get(API_URL + `get-code-bender-settings/${companyId}`,config);
  return response.data;
}
const getManageScore = async (requestData: any,token:string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const {companyId,id} = requestData;
  const response = await axios.get(API_URL + `get-score-by-companyid-and-gamesettingsid/${companyId}/${id}`,config);
  return response.data;
}
const contactUs = async (requestData: any) => {

  const response = await axios.post(API_URL + 'contact-us', requestData)
  return response.data;
}

const companyService = {
    createBender,
    getCodeBenderSettings,
    getManageScore,
    deleteBender,
    updateCodeBenderSettings,
    updateCompanyInfo,
    updatePassword,
    verifyEmail,
    recoverPassword,
    resetPassword,
    contactUs,
}

export default companyService;