import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import codebenderService from './codebenderService';

interface RequestData {
  id: string | undefined;
  handle: string | undefined;
  // payload: any;
}

interface StartTestState {
  resultData: RequestData | any;
  isResultError: boolean;
  isResultSuccess: boolean;
  isResultLoading: boolean;
  resultMessage: any;
}

const initialState = {
  isResultError: false,
  isResultSuccess: false,
  isResultLoading: false,
  resultMessage: '',
  resultData: []
} as StartTestState

export const startTestBender = createAsyncThunk(
  'codebender/startTestBender',
  async (payload: RequestData, thunkAPI) => {
    try {
      return await codebenderService.startTestBender(payload);
    } catch (error: any) {
      const resultMessage =
        (error.response &&
          error.response.data &&
          error.response.data.resultMessage) ||
        error.resultMessage ||
        error.toString()
      return thunkAPI.rejectWithValue(resultMessage)
    }
  }
)

export const startTestBenderSlice = createSlice({
  name: 'startTestBender',
  initialState,
  reducers: {
    reset: (state) => {
      state.isResultLoading = false
      state.isResultSuccess = false
      state.isResultError = false
      state.resultMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startTestBender.pending, (state) => {
        state.isResultLoading = true
      })
      .addCase(startTestBender.fulfilled, (state, action) => {
        state.isResultLoading = false
        state.isResultSuccess = true
        state.resultData = action.payload
        state.resultMessage = action.payload.message
      })
      .addCase(startTestBender.rejected, (state, action) => {
        state.isResultLoading = false
        state.isResultError = true
        state.resultMessage = action.payload
      })
  },
})

export const { reset } = startTestBenderSlice.actions;
export default startTestBenderSlice.reducer;