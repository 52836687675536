import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import recaptchaService from './recaptchaService';
import { getCompanyToken } from '../helper/helper';


interface RequestData {
  user_handle: string;
  id: string;
}

interface DeactivateRecaptchaState {
requestData: RequestData | null;
isError: boolean;
isSuccess: boolean;
isLoading: boolean;
message: any;
}


const initialState = {
  requestData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as DeactivateRecaptchaState

export const deactivateRecaptchaApiKey = createAsyncThunk('codebender/deactivate/recaptchaapikey', async (requestData: RequestData, thunkAPI) => {
    try {
      const token =  getCompanyToken(thunkAPI);

      requestData = { ...requestData};
      return await recaptchaService.deactivateRCApikey(requestData,token)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })

export const deactivateRecaptchaApiKeySlice = createSlice({
  name: 'deactivateRecaptchaApiKeyAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(deactivateRecaptchaApiKey.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deactivateRecaptchaApiKey.fulfilled, (state, action) => {
        if(action.payload.status === 500){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.errorMessage
            state.requestData = null
        }else{
            state.isLoading = false
            state.isSuccess = true
            state.requestData = action.payload
        }
      })
      .addCase(deactivateRecaptchaApiKey.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = null
      })
  }

})

export const { reset } = deactivateRecaptchaApiKeySlice.actions;
export default deactivateRecaptchaApiKeySlice.reducer;