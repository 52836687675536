import React, { FC, useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { updateCompany, reset } from '../../features/company/updateCompanySlice';
import {updateCompanyLocalStorageInfo} from '../../utils/constFunctions';
import UpdatePassword from './actions/UpdatePassword';


import {
  CCol,
  CForm,
  CFormLabel,
  CRow,
} from '@coreui/react';

const CompanySettings: FC = () =>{

const [location, setCountryValue] = useState('');
const countrys = useMemo(() => countryList().getData(), []);
//@ts-ignore
const companyInfo = JSON.parse(localStorage.getItem('companyData'));
const [formData, setFormData] = useState({
        company_name: companyInfo.company.user.company_name,
        location: companyInfo.company.user.location,
      });  
const { company_name } = formData;
const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.updateCompanyAction
  )
const dispatch = useAppDispatch();
const navigate = useNavigate();

useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset);
    }
    if (isSuccess || requestData) {
      toast.success('Profile updated..')
      navigate('/profile-settings')
    }
  }, [requestData, isError, isSuccess, message, navigate, dispatch])


const changeHandler = (location: any) => {
  setCountryValue(location);
}
 
const onChange = (e: { target: { name: any; value: any; }; }) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
}
const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if(!location){
      toast.error('Location is required..')
      return false;
    }
const updateData = {
    company_id: companyInfo.company.user.id,
    company_name,
    //@ts-ignore
    location: location.label, 
}
dispatch(updateCompany(updateData)).unwrap()
.then(data => {
   updateCompanyLocalStorageInfo(companyInfo,data)
   navigate('/profile-settings');
});
   
}
return (
    <CRow className="justify-content-center">
      <CCol xs={9}>
        <div className="row" style={{backgroundColor:'#FFF', padding:'20px'}}>
            <div className='col-md-4'>
                <h4>Account type</h4>
                <span className='btn btn-default' style={{backgroundColor:"#6c757d"}}>
                    FREE PLAN
                </span>
            </div>
            <div className='col-md-6'>
              <CForm onSubmit={onSubmit}>
               <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlTextarea1">Company Name</CFormLabel>
                  <input
                    id='company_name'
                    name='company_name'
                    value={company_name}
                    placeholder='Company Name'
                    onChange={onChange}
                    required>
                  </input>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlInput1">
                    Location - Current: <b className='text-info'>{companyInfo.company.user.location}</b>
                  </CFormLabel>
                  <Select 
                    id='location'
                    //@ts-ignore
                    options={countrys} 
                    value={location} 
                    onChange={changeHandler} 
                    required
                  />
                </div>
                <div className='form-group'>
                    <button className='btn btn-block btn-success' type='submit'>
                        Submit
                    </button>
                    <label>{isLoading}</label>
                </div>
              </CForm>
            </div>
            <div className='col-md-2'>
               <UpdatePassword 
                company_id={companyInfo.company.user.id}
                email={companyInfo.company.user.email}
               />
            </div>
        </div>
      </CCol>
    </CRow>
  )
}

export default CompanySettings;
