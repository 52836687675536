import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import { Link } from 'react-router-dom';
import { Props } from 'react-select';
import advert from '../../images/advert.gif';



export const ReCaptcha: FC<Props> = () => {

  return (
    <CContainer>
      <CRow>
        <CCol lg="6" className="bg-default py-3">
          <h1 className='thetitle-'>
              What is reCAPTCHA?
            </h1>
            <br></br>
            <p className='rcDescription'>reCAPTCHA is a free tool that helps to prevent spam and abuse on your website. It uses advanced techniques to differentiate between humans and bots.</p>
             
             <Link to='/api-reference'>
                    <Button variant="primary" size="lg" active>
                      Get started
                    </Button>
              </Link>
             
              <img className='advertImage' src={advert} alt="loading..." />
         </CCol>
          <CCol lg="6" className="bg-default py-3 codeBenderContent">
          <h1 className='thetitle-two'>Thelastiqtest reCAPTCHA</h1>
          <br></br>
          <p className='rcDescription'>
           Fraud on the internet is a major problem that costs businesses billions of dollars annually. To protect their websites and provide secure access to their customers, security teams must prevent hackers from gaining access and ensure that their website is always secure. reCAPTCHA has a long history of defending the internet and data for its network of more than 5 million sites. thelastiqtest reCAPTCHA  service offers additional security features, such as using thelastiqtest A1-B2-C3-D4 IQ questions, specifically designed for enterprise security needs. 
           <br></br><br></br>
           With thelastiqtest reCAPTCHA, businesses can defend their website against common web-based attacks, such as credential stuffing, account takeovers, and scraping, helping to prevent costly exploits from both human and automated attackers. Additionally, thelastiqtest reCAPTCHA,will not interrupt users with challenges, allowing it to be implemented on all webpages where customers interact with the business's services.
          </p>
          <Link to='/api-reference'>
                    <Button variant="default" size="lg" active>
                      Get started
                    </Button>
              </Link>
        </CCol>
      </CRow>
      <CRow className='moreInfo'>
                  <CCol lg="12" className="bg-default py-3">
                  <h1 className='thetitle-two'>Your success is our <span className='CodeBenderPrimaryColor'>highest priority</span></h1> 
                  <p>
                   Our API can be utilized to distinguish between humans and bots on websites in order to prevent spam and abuse. 
                    While it is simple for humans to complete, it is difficult for bots and other malicious software to solve. 
                    By incorporating thelastiqtest's reCAPTCHA into a website, you can prevent automated programs from accessing 
                    the site while allowing legitimate users to easily enter.
                    </p>
                  </CCol>
                </CRow>
    </CContainer>
  )
}