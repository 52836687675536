
import React, { FC, useState,useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../SubShare/Spinner';
import { signup, reset } from '../../features/auth/companySignUpSlice';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCompanyLocalStorage } from '../../hooks/companyAuth';

const CompanySignUp: FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    company_name: '',
    password: '',
  });
const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
const { email, company_name, password } = formData;
const navigate = useNavigate();
// @ts-ignore
const dispatch = useAppDispatch();


const { company, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.authCompanySignUp
  )
const checkCompanyLStorage = useCompanyLocalStorage();

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset);
    }
    if (localStorage.getItem('isNavigatePage') && isSuccess && company) { 
        const page_to_visit = localStorage.getItem('isNavigatePage');
        window.location.href = '/'+page_to_visit;
    }
    if (isSuccess || company || checkCompanyLStorage) {
      window.location.reload();
      window.location.href = '/company-dashboard';
    }

    
  }, [company, isError, isSuccess, message, navigate, dispatch, checkCompanyLStorage])


  const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }
  const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if(password.length < 8 ){
      setPasswordErrorMessage('Password must more than 7 characters.');
       return false;
    } 
    setPasswordErrorMessage('')
    const companyData = {
        email,
        company_name,
        password
      }

    dispatch(signup(companyData));

    if (!isError) {
      setFormData({
          email : '',
          company_name : '',
          password: '',
      });
    }
    
  }
  
if (isLoading) {
    return <Spinner />
  }
  return (
    <Container>
      <Row className="justify-content-md-center">
          <Col className="col-md-4"> 
            <form className='company-signin-form' onSubmit={onSubmit}>
              <div className='form-group'>
                      <h4>Get started with your Free account.</h4>
              </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className=''
                    id='company_name'
                    name='company_name'
                    value={company_name}
                    placeholder='Company Name'
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className=''
                    id='email'
                    name='email'
                    value={email}
                    placeholder='Email'
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    className=''
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Password'
                    onChange={onChange}
                    required
                  />
                  <label className='red-text'>{passwordErrorMessage}</label>
                </div>
              <div className='form-group'>
                <button className='btn btn-block btn-success' type='submit'>
                    Sign Up
                </button>
              </div>
              <div className='form-group'>
                <span>Already have a account? <a className='link' href='/start-company'> Log in</a></span>
              </div>
            </form>
          </Col>
      </Row>
    </Container>
  )
}

export default CompanySignUp;