import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import companyService from './companyService';
import { getCompanyToken } from '../helper/helper';

interface RequestData {
  company_id: string;
  company_name: string,
  location: string;
}
interface UpdatePasswordData {
  company_id: string,
  email: string,
  password: string, 
  newPassword: string,
}
interface updateCompanyState {
  requestData?: RequestData | null,
  isError: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  message: any,
  }

const initialState = {
  requestData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as updateCompanyState

export const updateCompany = createAsyncThunk('codebender/updatecompany', async (requestData: RequestData, thunkAPI) => {
    try {
      const token =  getCompanyToken(thunkAPI);

      requestData = { ...requestData};
      return await companyService.updateCompanyInfo(requestData,token)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
})
export const updateCompanyPassword = createAsyncThunk('codebender/updatecompany', async (requestData: UpdatePasswordData, thunkAPI) => {
  try {
    const token =  getCompanyToken(thunkAPI);

    requestData = { ...requestData};
    return await companyService.updatePassword(requestData,token)
  } catch (error: any) {
     
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
     
      if(error.response.status === 400){
        message = error.response.data.data;
      }
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateCompanySlice = createSlice({
  name: 'updateCompanyAction',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    updateDetails: (state, action) => {
        state.requestData = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
    .addCase(updateCompany.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        if(action.payload.company.status === 500){
            state.isLoading = false
            state.isError = true
            state.message = action.payload.company.errorMessage
            state.requestData = null
        }else{
            state.isLoading = false
            state.isSuccess = true
            state.requestData = action.payload
        }
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.requestData = null
      })
  }

})

export const { reset } = updateCompanySlice.actions;
export const { updateDetails } = updateCompanySlice.actions;

export default updateCompanySlice.reducer;