import React, {FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createCodeBender, reset } from '../../features/company/createCodeBenderSlice';
import {
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormLabel,
  CRow,
} from '@coreui/react';


const CreateNewCodeBender: FC = () => {

const { company } = useAppSelector((state) => state.auth);
const [formData, setFormData] = useState({
        title: '',
        instructions: '',
        question_type: '',
        wordLength: '',
        numberOfQuestion: '',
        test_time: '',
        expiry_date: ''
      });
const { title, instructions, question_type, wordLength, numberOfQuestion, test_time, expiry_date } = formData;
const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.createCodeBenderAction
  )
const dispatch = useAppDispatch();
const navigate = useNavigate();

useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset);
    }
    if (isSuccess || requestData) {
      toast.success('Record saved..')
      navigate('/manage-codebender')
    }
  }, [requestData, isError, isSuccess, message, navigate, dispatch])
  
const onChange = (e: { target: { name: any; value: any; }; }) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
}
const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

const createData = {
  //@ts-ignore
    company_id: company.company.user.id,
    title,
    instructions, 
    equalQuestion: true,
    question_type: question_type, 
    wordLength: wordLength, 
    numberOfQuestion: numberOfQuestion,
    test_time: test_time,
    expiry_date: expiry_date
}

dispatch(createCodeBender(createData));

if (!isError) {
    setFormData({
        title: '',
        instructions: '',
        question_type : '',
        wordLength: '',
        numberOfQuestion: '',
        test_time: '',
        expiry_date: ''
      });
}
   
}
  return (
    <CRow className="justify-content-center">
      <CCol xs={6}>
        <CCard className="mb-4">
          <CCardBody>
              <CForm onSubmit={onSubmit}>
               <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlTextarea1">Title (eg. IQ test)</CFormLabel>
                  <input
                    id='title'
                    name='title'
                    value={title}
                    placeholder='Title'
                    onChange={onChange}
                    required>
                  </input>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlTextarea1">Instructions (Optional)</CFormLabel>
                  <textarea
                    id='instructions'
                    name='instructions'
                    value={instructions}
                    placeholder='Instructions (Optional)'
                    onChange={onChange}
                    rows={3}
                    required>
                  </textarea>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlInput1">Question Type</CFormLabel>
                   <select 
                        id='question_type'
                        name='question_type'
                        value={question_type}
                        onChange={onChange}
                        required>
                        <option value="">Select Type</option>
                        <option value="characters">Characters Question</option>
                        <option value="numbers">Number Question</option>
                        <option value="random">Random Characters and Numbers Question</option>
                        <option value="complex">Characters/Numbers Question (complex)</option>
                   </select>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlInput1">Word Length</CFormLabel>
                   <select 
                        id='wordLength'
                        name='wordLength'
                        value={wordLength}
                        onChange={onChange}
                        required>
                            <option value="">Select Word Length</option>
                            {(() => {
                                const arr = [];
                                for (let i = 3; i < 21; i++) {
                                    arr.push(
                                        <option value={i} key={i}>{i}</option>
                                    );
                                }
                                return arr;
                            })()}
                   </select>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlInput1">Number Of Questions</CFormLabel>
                   <select 
                        id='numberOfQuestion'
                        name='numberOfQuestion'
                        value={numberOfQuestion}
                        onChange={onChange}
                        required>
                        <option value="">Select Number Of Questions</option>
                            {(() => {
                                const arr = [];
                                for (let i = 10; i < 110; ) {
                                    arr.push(
                                        <option value={i} key={i}>{i}</option>
                                    );
                                    i += 10;
                                }
                                return arr;
                            })()}
                   </select>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlInput1">
                    Test Time (How many minutes will this test take?)
                  </CFormLabel>
                   <select 
                        id='test_time'
                        name='test_time'
                        value={test_time}
                        onChange={onChange}
                        required>
                        <option value="">Select Time</option>
                            {(() => {
                                const arr = [];
                                for (let i = 10; i < 181; ) {
                                    arr.push(
                                        <option value={i} key={i}>{i} Minutes</option>
                                    );
                                    i += 10;
                                }
                                return arr;
                            })()}
                   </select>
                </div>
                <div className="form-group">
                  <CFormLabel htmlFor="exampleFormControlInput1">Expiry Date</CFormLabel>
                  <input
                        type='date'
                        className=''
                        id='expiry_date'
                        name='expiry_date'
                        value={expiry_date}
                        placeholder='Expiry Date'
                        onChange={onChange}
                        required
                    />
                </div>
                <div className='form-group'>
                    <button className='btn btn-block btn-success' type='submit'>
                        Submit
                    </button>
                    <label>{isLoading}</label>
                </div>
              </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default CreateNewCodeBender;
