import React, { FC, useState } from 'react';
import Spinner from '../../SubShare/Spinner';
import Container from 'react-bootstrap/Container';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { recoverPassword } from '../../../features/company/recoverPasswordSlice';
import { CCol, CRow } from '@coreui/react';


const RecoverPassword: FC = () =>{

const [formData, setFormData] = useState({
        email: '',
      });
    const { email } = formData;
const { requestData, isLoading } = useAppSelector((state) => state.recoverPasswordAction);
const dispatch = useAppDispatch();


const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const userData = {
        email
      }
      //@ts-ignore
    dispatch(recoverPassword(userData));
    setFormData({
        email : '',
    });
}

if (isLoading) {
    return <Spinner />
}

return (
    <Container>
      <CRow>
        <CCol lg="6" className="container h-100 d-flex justify-content-center py-3">
        {/* @ts-ignore */}
        <div className='section' xs={12} md={6}>
            <form className='company-signin-form' onSubmit={onSubmit}>
            <h2> Reset your password</h2>
            <p>Please provide the email address you used when you signed up for your TheLastIQTest account.</p>
            <div className='form-group'>
                <input
                type='email'
                className=''
                id='email'
                name='email'
                value={email}
                placeholder='Email'
                onChange={onChange}
                required
                />
            </div>
            <div className='form-group'>
            <button className='btn btn-block btn-success' type='submit' style={{color:'#FFF'}}>
                Send email 
            </button>
             <label className='red-text'>{requestData?.result.status === 401 ? requestData?.result.errorMessage : ''}</label>
             <label className='red-green'>{requestData?.result.status !== 401 ? requestData?.result.message : ''}</label>
            </div>
            </form>
        </div>
        </CCol>
      </CRow>
    </Container>
  );
}

export default RecoverPassword;