import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CompanySignInForm from '../CompanySignInForm';
import { Col } from 'react-bootstrap';

interface Props{
    status: number;
    message?: string; 
    errorMessage?: any;
}

const RoverPasswordFeedback: FC<Props> = ({
    message, errorMessage, status
  }) => {
    
return (
    <Container>
      <Row>
        {/* @ts-ignore */}
        <div className='section' xs={6} md={12}>
          
          {
            status === 200 ? 
            <Col className="col-md-12"> 
            <h4 className='green-text'>{message}</h4>
                <CompanySignInForm />
            </Col> 
            :
            <Col className="col-md-12"> 
            <h4 className='red-text'>{errorMessage}</h4>
            <div className='form-group'>
                <p>Already have a account? <a className='link' href='/start-company'> Log in</a></p>
                <p>Forgot password? <a className='link' href='/recover-password'> Recover password.</a></p>
              </div>
            </Col>
          }
          
        </div>
      </Row>
    </Container>
  );
}

export default RoverPasswordFeedback;