import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {testGrade} from "../../utils/constFunctions";


interface  IGameComplete{
    prop?: object;
}

const GameComplete: FC<IGameComplete> = ({prop}) => {

let finalGameScoreInfo = localStorage.getItem("finalGameScore");
finalGameScoreInfo = '' + finalGameScoreInfo;
const theResult = JSON.parse(finalGameScoreInfo);
localStorage.removeItem('userData');
localStorage.removeItem('companyData');


return (
    <>
    <section className="row">
        <h1>Hi {theResult.firstName}, thank you for completing the thelastiqtest assessment.</h1>
        
        <div className='row' style={{marginTop:'90px'}}>
            <div className='col-md-8'>
                <div className="circle">
                    <h3>
                          {testGrade(theResult.totalQuestions,theResult.score)}% 
                          <p>Time Complexity: <br /> {theResult.minutes} of {theResult.gameMinutes} Minutes</p>
                    </h3>
                </div>  
            </div>
            <div className='col-md-3' style={{marginLeft:'-310px', marginTop:'120px'}}>
                <Link to='/glabal-score'>
                        <span className='btn btn-success'>View Global Score</span>
                </Link>
            </div>
        </div>
    </section>
    </>
    )
}

export default GameComplete;