import React, { FC, useState } from 'react';
import Spinner from '../SubShare/Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import { contactUs } from '../../features/company/contactUsSlice';
import { toast } from 'react-toastify';
import { CCard, CCardBody, CCol, CForm, CFormLabel, CRow } from '@coreui/react';
import { TheLastIQReCaptcha } from 'react-thelastiq-recaptcha';


const ContactUs: FC = () =>{
const [isCaptchaVerify, setIsCaptchaVerify] = useState(true);
const [formData, setFormData] = useState({
        subject: '', fullname:'', email:'', message:''
      });
    const { subject, fullname, email, message } = formData;
const { requestData, isLoading } = useAppSelector((state: { contactUsAction: any; }) => state.contactUsAction);
const dispatch = useAppDispatch();


const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const reqData = {
        subject,
        fullname,
        email,
        message,
      }
      //@ts-ignore
    dispatch(contactUs(reqData));
    setFormData({
        subject : '',
        fullname : '',
        email : '',
        message : '',
    });
}

if (isLoading) {
    return <Spinner />
}
if(requestData?.message){
    toast.success(requestData?.message);
}

return (
    <CRow>
        <CCol lg="12" className="container h-100 d-flex justify-content-center py-12">
            <CCol xs={6} className='justify-content-center'>
            <CCard className="justify-content-center">
                <CCardBody>
                    <CForm onSubmit={onSubmit}>
                        <h2>Contact Us</h2>
                    <div className="form-group">
                        <CFormLabel htmlFor="exampleFormControlInput1">Subject</CFormLabel>
                        <select 
                            id='subject'
                            name='subject'
                            value={subject}
                            onChange={onChange}
                            required>
                            <option value="">Select Subject</option>
                            <option value="Report a bug">Report Bug</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">Full Name</CFormLabel>
                        <input
                        id='fullname'
                        name='fullname'
                        value={fullname}
                        placeholder='Full Name'
                        onChange={onChange}
                        required>
                        </input>
                    </div>
                    <div className="form-group">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">Email</CFormLabel>
                        <input
                        type='email'
                        id='email'
                        name='email'
                        value={email}
                        placeholder='Email'
                        onChange={onChange}
                        required>
                        </input>
                    </div>
                    <div className="form-group">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">Message</CFormLabel>
                        <textarea
                        id='message'
                        name='message'
                        value={message}
                        placeholder='message'
                        onChange={onChange}
                        rows={3}
                        required>
                        </textarea>
                    </div>
                    <div className='form-group'>
                    <TheLastIQReCaptcha 
                                reCaptchaKey={'30815c93-03af-4a1b-80f9-79015de0ded8'} 
                                onVerifyCaptcha={(result) => {
                                    setIsCaptchaVerify(!result);
                                  }}
                        />
                        <button disabled={isCaptchaVerify} className='btn btn-block btn-success' type='submit' style={{color:'#FFF'}}>
                            Submit
                        </button>
                        <label>{isLoading}</label>
                    </div>
                    </CForm>
                </CCardBody>
            </CCard>
            </CCol>
        </CCol>
  </CRow>
  );
}

export default ContactUs;