import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import startCodeBenderService from './startCodeBenderService';

const companyData = localStorage.getItem('companyData');
const company = companyData ? JSON.parse(companyData) : null;

interface AuthSate{
  company: [] | null,
  isError: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  message: any,
}
const initialState = {
  company: company ? company : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
} as AuthSate

interface Company {
  email: string;
  password: string;
}

export const login = createAsyncThunk('auth/login', async (company: Company, thunkAPI) => {
    try {
      
      return await startCodeBenderService.companySignIn(company)
    } catch (error: any) {
       
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
       
        if(error.response.status === 400){
          message = error.response.data.data;
        }
      return thunkAPI.rejectWithValue(message)
    }
  })
export const logout = createAsyncThunk('auth/logout', async () => {
    await startCodeBenderService.logout()
})
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        if(action.payload.company.status === 500){
          state.isLoading = false
          state.isError = true
          state.message = action.payload.company.errorMessage
          state.company = null
        }else{
            state.isLoading = false
            state.isSuccess = true
            state.company = action.payload
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.company = null
      })
    .addCase(logout.fulfilled, (state) => {
        state.company = null
      })
  }

})

export const { reset } = authSlice.actions;
export default authSlice.reducer;