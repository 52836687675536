import React, { FC} from "react";
import Countdown from "react-countdown";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import {currentGameCompleted, gameCompleteData} from "../../utils/constFunctions";
import { finishCodeBender } from '../../features/codebender/finishCodebenderSlice';

interface PropsTimer {
  gameMinutes?: any;
  gameSetttingsId: number;
  user?: any;
  company?: any;
  score?: number;
  isQuestionComplete: boolean;
  numberOfQuestions: number;
}
interface PropsRenderTime {
  days?: any;
  hours: any;
  minutes: any;
  seconds: any;
  completed: boolean;
}

const Completionist = () => <span className="countdown-timer">Time Over!</span>;

const renderer: FC<PropsRenderTime> = ({ hours, minutes, seconds, completed}) => {
  const gameTimeOver = { hours: hours, minutes: minutes, seconds: seconds};
  localStorage.setItem(
    "currentGameTimer",
    JSON.stringify({...gameTimeOver})
  );

  if (completed) {
    return <Completionist />;
  } else {
    
    return (
      <span className="countdown-timer">
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};
const getLocalStorageValue = (s: string) => localStorage.getItem(s);

const GameTimer: FC<PropsTimer> = ({ gameMinutes, user, company, score, gameSetttingsId, isQuestionComplete, numberOfQuestions}) => {
  const actualGameTime = 1 * gameMinutes * 60 * 1000;
  const [data, setData] = useState(
    { date: Date.now(), delay: actualGameTime } 
  );

  useEffect(() => {

    const savedDate = getLocalStorageValue("gameTimer");
    if (savedDate != null && !isNaN(savedDate as unknown as number)) {
      const currentTime = Date.now();
      const actualTimer = parseInt(savedDate, 10) - currentTime;
      
      if (actualTimer > actualGameTime) {
        
        if (localStorage.getItem("gameTimer") != null)
            localStorage.removeItem("gameTimer");
      } else {
        //No update the game timer with the current date
        setData({ date: currentTime, delay: actualTimer });
      }
    }
  }, [actualGameTime]);

const dispatch = useDispatch();

const finishCurrentGame = async () => {
    const finishData = gameCompleteData(numberOfQuestions,user.user.id,company.id,gameSetttingsId,score,gameMinutes);
    // @ts-ignore: Unreachable code error
  dispatch(finishCodeBender(finishData));
  currentGameCompleted(user.user.first_name, user.gameTimer, numberOfQuestions,score,gameMinutes,localStorage.getItem("currentGameTimer"));
}

if(isQuestionComplete){
  finishCurrentGame()
}

  return (
   <>
      <Countdown
        date={data.date + data.delay}
        renderer={renderer}
        onStart={(actualTime) => {
         
          if (localStorage.getItem("gameTimer") == null)
            localStorage.setItem(
              "gameTimer",
              JSON.stringify(data.date + data.delay)
            );
            
        }}
        
        onComplete={() => {
 
        if (localStorage.getItem("gameTimer") != null)
            finishCurrentGame();
          localStorage.removeItem("gameTimer");
        }}
      />
      </>
  );
};

export default GameTimer;
