import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useTypedSelector';
import {Table, Spinner } from 'react-bootstrap';
import {
  CContainer,
  CCol,
  CRow,
} from '@coreui/react';
import CreateAPIKey from '../SubShare/modal/CreateAPIKey';
import DeactivateAPIKey from '../SubShare/modal/DeactivateAPIKey';
import { getRecaptchaApiKeys } from '../../features/recaptcha/getRecaptchaApiKeySlice';
import RecaptchaNav from '../nav/RecaptchaNav';
import { toast } from 'react-toastify';
import CIcon from '@coreui/icons-react';
import {cilCopy} from '@coreui/icons';



const APIKey : FC = () =>{
  
  const { company } = useAppSelector((state: { auth: any; }) => state.auth);
  const { resultData, isResultLoading, isResultError, resultMessage } = useAppSelector((state) => state.getRecaptchaApiKeysAction);

  const dispatch = useAppDispatch();

useEffect(() => {
      if (isResultError) {
        toast.error(resultMessage)
      }
}, [isResultError,resultMessage])

if (company === null) {
  const nav_to_this_page = 'apikey'
  localStorage.setItem("isNavigatePage", nav_to_this_page);
  window.location.href = '/start-company';
}

  useEffect(() => {
    
    if(company !== null){
      // @ts-ignore
      dispatch(getRecaptchaApiKeys(company.company.user.id));
    }
  }, [dispatch,company] );
return (
    <CContainer>
      <CRow>
      <CCol lg="2" className="bg-default py-3">
          <RecaptchaNav />
        </CCol>
        <CCol lg="9" className="bg-default py-3">
          {
            company ?

          <div className='api-ref-content'>
              <h6 className='title-apikey'>GET reCAPTCHA API Key</h6>
              <p>The following is a list of your confidential API keys. It is important to keep in mind that once generated,
                 these keys will not be shown again.<br></br><br></br>
                  It is imperative that you keep your API key private and do not reveal
                  it to others or include it in the browser or client-side code. To ensure the security of your account, 
                  thelastiqtest may replace any API keys that have been discovered to have been made public for safety purposes.</p>
              <br></br><br></br>
                <CreateAPIKey 
                  // @ts-ignore
                companyId={company.company.user.id}
                />
                <br></br><br></br>
                {isResultLoading ? <Spinner animation="border" variant="info" /> : ''}
                <>
                { !resultData ? 
                  <span className="text-danger" style={{marginTop:'270px'}}>You don't have any record yet. <a href="/create-codebender" className='btn btn default'>Click here</a> to create a new record</span>
                  :
                  <Table responsive="sm">
                        <thead>
                            <tr style={{border:'3px #FFF solid', fontSize:'12px'}}>
                                <th style={{width:'143px'}}>NAME</th>
                                <th>KEY</th>
                                <th>LAST USED</th>
                                <th>CREATED</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='rc-api-table-content'>
                      
                          <>
                          {resultData.result && resultData.result.map((key: any, index: any) =>
                                <tr key={index} style={{color:'#000'}}>
                                  <td>{key.domain_name}</td>
                                  <td>{key.api_key}</td>
                                  <td>{key.updated_at}</td>
                                  <td>{key.created_at}</td>
                                  <td>
                                  <label className='copy-test-link' style={{marginRight:'9px'}}
                                    onMouseDown={() => toast.success('Api key copied..')}
                                    onClick={() => navigator.clipboard.writeText(`${key.api_key}`)}>
                                      <CIcon icon={cilCopy} className="me-2" /><label style={{fontSize:'10px', cursor:'pointer'}}>Copy</label>
                                  </label>
                                    <DeactivateAPIKey 
                                        id={key.id} 
                                        domainName={key.domain_name} 
                                        apikey={key.api_key}
                                         // @ts-ignore
                                        companyId={company.company.user.id}
                                    />
                                  </td>
                                </tr>
                              )}
                          </>
                        </tbody>
                  </Table>
                }
                </>
          </div>
          : ''
          
          }
         </CCol>
      </CRow>
    </CContainer>
  )
}

export default APIKey;