import axios from 'axios'
import { config } from '../../common/config';

const API_URL = config.baseUrl.default;

const startCodeBender = async (userData: any) => {
    const response = await axios.post(API_URL + 'set-question', userData);

    if (response.data && response.data.user && response.status === 200) {
        localStorage.setItem('userData', JSON.stringify(response.data));
        localStorage.setItem('stateUserGameData', JSON.stringify(response.data));
    }
  
    return response.data
} 

const companySignUp = async (company: any) => {
  const response = await axios.post(API_URL + 'company-signup', company);
    if (response.data && response.data.company.status === 200) {
      localStorage.setItem('companyData', JSON.stringify(response.data));
    }
  
    return response.data
}
const companySignIn = async (company: any) => {
  const response = await axios.post(API_URL + 'company-signin', company);
  if (response.data && response.data.company.status === 200) {
      localStorage.setItem('companyData', JSON.stringify(response.data));
  }
  return response.data;
}

const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('companyData');
    localStorage.clear();
    window.location.href = '/';
}

const startCodeBenderService = {
    logout,
    startCodeBender,
    companySignUp,
    companySignIn,
}
  
  export default startCodeBenderService