import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CIcon from '@coreui/icons-react';
import { cilTask } from '@coreui/icons';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedSelector';
import { updateCompanyPassword, reset } from '../../../features/company/updateCompanySlice';
import { Spinner } from 'react-bootstrap';
import { passwordStrength } from 'check-password-strength';


const UpdateBtn = styled.span`
    background: transparent;
    border-color: transparent;
    color: #39c0ed;
    cursor: pointer;
    float: right;
    font-size: 12px; 
    margin-top: 230px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
    font-weight: 500;
    padding: 0.625rem 1.5rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
`;
interface Props{
  company_id: string; 
  email: string; 
}

export const chnagePasswordStrengthValidation = (password: any) => {
    const validate = passwordStrength(password).value;
    if(validate === 'Too weak' || validate === 'Weak'){

        return (
            <>
            <label> 
                Your password is <b className="text-danger">{validate} </b>. Try to include special characters, and combination of upper and lawercase 
            </label>
            </>
        )
    }
    return (
        <>
        <label> 
            Your password is <b className="text-success">{validate}</b> 
        </label>
        </>
    )

}

const UpdatePassword: FC<Props> = ({
  company_id,
  email,
}) => {

  const [show, setShow] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { requestData, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.updateCompanyAction
  )

const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
  });
const { password, newPassword } = formData;

const dispatch = useAppDispatch();
const navigate = useNavigate();

useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
    if (isSuccess || requestData) {
      toast.success('Password updated..');
      window.location.reload();
    }
}, [requestData, isError, isSuccess, message, navigate, dispatch])

const onChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
}
const onKeyUp = (e: any) => {
const validatePassword = chnagePasswordStrengthValidation(newPassword);
//@ts-ignore
setPasswordErrorMessage(validatePassword);
}
const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if(newPassword.length < 8 ){
        setPasswordErrorMessage('Password must more than 7 characters.');
         return false;
      } 
    const updateData = {
        company_id: company_id,
        email: email,
        password, 
        newPassword, 
    }
    dispatch(updateCompanyPassword(updateData));
}

return (
    <>
    <UpdateBtn onClick={handleShow}>
        <CIcon icon={cilTask} className="me-2" />
        Change Password
    </UpdateBtn>
      <Modal show={show} onHide={handleClose}>
       
        <Modal.Body>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Old Passsword</label>
                  <input
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Old Password'
                    onChange={onChange}
                    required>
                  </input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">New Passsword</label>
                  <input
                    id='newPassword'
                    name='newPassword'
                    value={newPassword}
                    placeholder='New Password'
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    required>
                  </input>
                </div>
                
                <div className='form-group'>
                    <button className='btn btn-block btn-success' type='submit'>
                        Update Password
                    </button>
                    <label>{isLoading}</label>
                    <span>{passwordErrorMessage}</span>
                </div>
            </form>
            <span className='color-primary'>
                {isError ? 
                 <><br /><br /><code className="PropTable-Code-module--cls2--1p8A9 PropTable-Code-module--cls1--2iQb5">{message}</code></>
                 : ''}
                  {isLoading ? <Spinner animation="border" variant="info" />: ''}
            </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdatePassword;
